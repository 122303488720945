import { useState, useEffect } from "react";
import MainContext from "../context";
import MainLayout from "../layouts/MainLayout";
import SidebarContain from "./SidebarContain";
import {Sidebar} from "../components/sidebar";
import DrawerActionButton from "../components/Drawer/DrawerActionButton";
import PagesContainer from "./PagesContainer";
import MainPage from "../pages/Jointcomponents/MainPage";
import {ResultPage} from "../pages/Jointcomponents";
import axios, {AxiosError} from "axios";
import {grey, red} from "@mui/material/colors";
import {useAuthUser} from "react-auth-kit";

function createArray(N) {
    return Array.from({length: N}, (_, index) => index + 1);
}

const Joint = () => {
    document.addEventListener("wheel", function(event) {
        if (document.activeElement.type === "number" &&
            document.activeElement.classList.contains("noscroll")) {
            document.activeElement.blur();
        }
    });
    const auth = useAuthUser()
    const [jointAlertOpen,setJointAlertOpen] =  useState(false);
    const [imgSrc, setImgSrc] = useState("SingleLap")
    const [getJointMaterial, setJointMaterial] = useState([]);
    const [getJointinfo, setJointinfo] = useState({
      unit:"1",
      flexibility: "",
      elasticity: "",
      fatigue: "No",
      configuration: "1",
      load_stress_value:"",
      load_stress:"Load",
      load_stress_symbole:", F",
      fastener_rows:"",
      strap_thickness:"",
      fastener_diameter:"",
      row_spacing:"",
      plate_thickness:"",
      joint_width:"",
      hole_type:"",
      fastener_type:"",
      joint_type:"",
      plate_combobox1: -1,
      plate_combobox2: -1,
      plate_combobox3: -1,
      strap_combobox1: -1,
      strap_combobox2: -1,
      strap_combobox3: -1,
      fastener_combobox1: -1,
      fastener_combobox2: -1,
      fastener_combobox3: -1,
      plate_elasticity:"",
      strap_elasticity:"",
      fastener_elasticity:"",
      showJointInfo:true,
      strap_stringer: "Strap",
      alloy: "",
      temper: "",
  });

    const [state,setState] = useState({
        labels:  createArray(10),
         datasets: [
            {
                id: 1,
                label: 'Rainfall',
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 0.1,
                data: [0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0],
            }
        ]
    });
    const [result,setResult] = useState(false)
    const [error, setError] = useState("");
    const [drawerOpenJoint, setDrawerOpenJoint] = useState(false);
    const [jointInputError, setJointInputError] = useState({
        load_stress_value: false,
        fastener_rows:false,
        strap_thickness:false,
        fastener_diameter:false,
        row_spacing:false,
        plate_thickness:false,
        joint_width:false,
        plate_elasticity:false,
        strap_elasticity:false,
        fastener_elasticity:false,
        flexibility: false,
        elasticity:false,
        hole_type: false,
        fastener_type:false,
        joint_type:false,
        fastener_combobox1:false,
    })
    useEffect( () => {

        setError("");
        let values = {fatigue: getJointinfo.fatigue}
        async function fetchData() {
            try {
                const response = await axios.post(auth().website + "/JointMaterial", values
                );
                setJointMaterial(response.data);
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        fetchData();
    }, []);

    const setJointInfo = (event) => {
        switch (event.target.name) {
            case "plate_combobox1":
                setJointinfo({...getJointinfo,["alloy"]: "",["temper"]: "",["plate_combobox2"]: -1, ["plate_combobox3"]: -1, ["plate_elasticity"]:"", [event.target.name]: event.target.value,
                });
                break;
            case "plate_combobox2":
                let tmp1 = ""
                if (event.target.value !== -1) {
                    if (getJointinfo.plate_combobox1 === "0"){
                        tmp1 = getJointMaterial[7][event.target.value]
                    }
                    if (getJointinfo.plate_combobox1 === "1"){
                        tmp1 = getJointMaterial[9][event.target.value]
                    }
                    if (getJointinfo.plate_combobox1 === "2"){
                        tmp1 = getJointMaterial[11][event.target.value]
                    }
                }
                setJointinfo({...getJointinfo,["alloy"]:tmp1,["temper"]: "",["plate_combobox3"]: -1, ["plate_elasticity"]:"", [event.target.name]: event.target.value,
                });
                break;
            case "plate_combobox3":
                let tmp2 = ""
                if (event.target.value !== -1) {
                    if (getJointinfo.plate_combobox1 === "0"){
                        tmp2 = getJointMaterial[8][getJointinfo.plate_combobox2][event.target.value]
                    }
                    if (getJointinfo.plate_combobox1 === "1"){
                        tmp2 = getJointMaterial[10][getJointinfo.plate_combobox2][event.target.value]
                    }
                    if (getJointinfo.plate_combobox1 === "2"){
                        tmp2 = getJointMaterial[12][getJointinfo.plate_combobox2][event.target.value]
                    }
                }
                let temp = event.target.value;
                let value1 = {"plate_combobox1":getJointinfo.plate_combobox1, "plate_combobox2": getJointinfo.alloy, "plate_combobox3": tmp2, "unit": getJointinfo.unit}
                setError("");
                async function fetchData() {
                    try {
                        const response = await axios.post(auth().website + "/plateElasticity", value1
                        );
                        setJointinfo({...getJointinfo, ["temper"]: tmp2, ["plate_elasticity"]: response.data, [event.target.name]: temp,});
                       } catch (err) {
                            if (err && err instanceof AxiosError)
                                setError(err.response?.data.message);
                            else if (err && err instanceof Error) setError(err.message);

                       }
                    }
                    fetchData();
                break;
            case "strap_combobox1":
                setJointinfo({...getJointinfo,["strap_combobox2"]: -1, ["strap_combobox3"]: -1, ["strap_elasticity"]:"", [event.target.name]: event.target.value,
                });
                break;
            case "strap_combobox2":
                setJointinfo({...getJointinfo,["strap_combobox3"]: -1, ["strap_elasticity"]:"", [event.target.name]: event.target.value,
                });
                break;
            case "strap_combobox3":
                let temp1 = event.target.value;
                let value2 = {"strap_combobox1":getJointinfo.strap_combobox1, "strap_combobox2": getJointinfo.strap_combobox2, "strap_combobox3": event.target.value, "unit": getJointinfo.unit}
                setError("");
                async function fetchData2() {
                    try {
                        const response = await axios.post(auth().website + "/strapElasticity", value2
                        );
                        setJointinfo({...getJointinfo,["strap_elasticity"]: response.data, [event.target.name]: temp1});
                    } catch (err) {
                        if (err && err instanceof AxiosError)
                            setError(err.response?.data.message);
                        else if (err && err instanceof Error) setError(err.message);


                    }
                }
                fetchData2();

                break;
            case "fastener_combobox1":
                setJointinfo({...getJointinfo,["fastener_combobox2"]: -1, ["fastener_combobox3"]: -1,["fastener_elasticity"]:"", [event.target.name]: event.target.value,
                });
                break;
            case "fastener_combobox2":
                setJointinfo({...getJointinfo,["fastener_combobox3"]: -1, ["fastener_elasticity"]:"", [event.target.name]: event.target.value,
                });
                break;
            case "fastener_combobox3":
                let temp2 = event.target.value;
                let value3 = {"fastener_combobox1":getJointinfo.fastener_combobox1, "fastener_combobox2": getJointinfo.fastener_combobox2, "fastener_combobox3": event.target.value, "unit": getJointinfo.unit}
                setError("");
                async function fetchData3() {
                    try {
                        const response = await axios.post(auth().website + "/fastenerElasticity", value3
                        );
                        setJointinfo({...getJointinfo,["fastener_elasticity"]: response.data, [event.target.name]: temp2,});
                    } catch (err) {
                        if (err && err instanceof AxiosError)
                            setError(err.response?.data.message);
                        else if (err && err instanceof Error) setError(err.message);


                    }
                }
                    fetchData3();
                break;
            case "flexibility":
                if (event.target.value == "Huth")
                {
                    setJointinfo({...getJointinfo,["showJointInfo"]:true, [event.target.name]: event.target.value,
                    });
                    break;
                }else{
                    setJointinfo({...getJointinfo,["showJointInfo"]:false, ["joint_type"]: "", [event.target.name]: event.target.value,
                    });
                    break;
                }
            case "elasticity":
                setJointinfo({...getJointinfo,["plate_combobox1"]: -1, ["plate_combobox2"]: -1, ["plate_combobox3"]: -1,["strap_combobox1"]: -1, ["strap_combobox2"]: -1, ["strap_combobox3"]: -1,["fastener_combobox1"]: -1, ["fastener_combobox2"]: -1, ["fastener_combobox3"]: -1, ["plate_elasticity"]:"", ["strap_elasticity"]:"",["fastener_elasticity"]:"",[event.target.name]: event.target.value,
                    });
                break;
            case "fatigue":
                if (event.target.value == "Yes")
                {
                    setJointinfo({...getJointinfo,["load_stress"]:"Stress", ["load_stress_symbole"]: ", \u03C3",["elasticity"]: "MMPDS",["plate_combobox1"]: -1, ["plate_combobox2"]: -1, ["plate_combobox3"]: -1, ["plate_elasticity"]:"",[event.target.name]: event.target.value,
                    });
                }else{
                    setJointinfo({...getJointinfo,["load_stress"]:"Load",["load_stress_symbole"]: ", F", ["plate_combobox1"]: -1, ["plate_combobox2"]: -1, ["plate_combobox3"]: -1,["plate_elasticity"]:"", [event.target.name]: event.target.value,
                    });
                }
                setError("");
                let value4 = {fatigue: event.target.value}
                async function fetchData4() {
                    try {
                        const response = await axios.post(auth().website + "/JointMaterial", value4
                        );
                        setJointMaterial(response.data);
                    } catch (err) {
                        if (err && err instanceof AxiosError)
                            setError(err.response?.data.message);
                        else if (err && err instanceof Error) setError(err.message);

                    }
                }
                fetchData4();
                break;
            case "configuration":
                if (event.target.value == "1"){
                    setImgSrc("SingleLap")
                    setJointinfo({...getJointinfo,["strap_stringer"]:"Strap",[event.target.name]: event.target.value,
                        });
                }
                if (event.target.value == "2"){
                    setImgSrc("DoubleLap")
                    setJointinfo({...getJointinfo,["flexibility"]:"Huth",["strap_stringer"]:"Strap",[event.target.name]: event.target.value,
                    });
                }
                if (event.target.value == "3"){
                    setImgSrc("HardPoint")
                    setJointinfo({...getJointinfo,["strap_stringer"]:"Strap",[event.target.name]: event.target.value,
                    });
                }
                if (event.target.value == "4"){
                    setImgSrc("Runout")
                    setJointinfo({...getJointinfo,["strap_stringer"]:"Stringer",[event.target.name]: event.target.value,
                    });
                }
                break;
            default:
               setJointinfo({...getJointinfo,[event.target.name]: event.target.value,
               });
               break;
        }
    }

    const [plotData,setplotData] = useState({
        "Bearing Load" : "",
        "Bearing Load/F x 100": "",
        "Local Peak Stress":"",
        "Severity Factor":"",
        'Fastener spring constant(in/lbf)': "",
        'Plate spring constant (in/lbf)': "",
        'Strap spring constant (in/lbf)': "",
        'Stringer spring constant (in/lbf)': "",
        'Fastener bearing load (in/lbf)': "",
        'Plate bypass load (in/lbf)': "",
        'Strap bypass load (lbf)': "",
        'Stringer bypass load (lbf)': "",
        'Plate peak stress (ksi)': "",
        'Strap peak stress (ksi)': "",
        'Stringer peak stress (ksi)': "",
        'Plate Severity Factor': "",
        'Strap Severity Factor': "",
        'Stringer Severity Factor': "",
        'Unfactored Fatigue Life Cycles Based on MMPDS': "",
        'Fatigue Life Cycle with Scatter Factor, Method 1' : "",
        'Fatigue Life Cycle with Scatter Factor, Method 2' : "",
        "Final Fatigue Life Cycles" : "",
        "Threshold Cycle with Scatter Factor, Method 1" : "",
        "Threshold Cycle with Scatter Factor, Method 2" : "",
        "Final Threshold Cycles" : "",
        "Fatigue Image" : "AL2024T33",
    });

    const runJoint = async (e) => {
        e.preventDefault()
        setError("");
        let er = [false,false,false,false,false,false,false,false,false,false, false, false, false, false, false, false]

        if (getJointinfo.load_stress_value === ""  || parseFloat(getJointinfo.load_stress_value) <= 0){
            er[0] = true
        }
        if (getJointinfo.fastener_rows === ""  || parseFloat(getJointinfo.fastener_rows) <= 0){
            er[1] = true
        }
        if (getJointinfo.strap_thickness === ""  || parseFloat(getJointinfo.strap_thickness) <= 0){
            er[2] = true
        }
        if (getJointinfo.fastener_diameter === ""  || parseFloat(getJointinfo.fastener_diameter) <= 0){
            er[3] = true
        }
        if (getJointinfo.row_spacing === ""  || parseFloat(getJointinfo.row_spacing) <= 0){
            er[4] = true
        }
        if (getJointinfo.plate_thickness === ""  || parseFloat(getJointinfo.plate_thickness) <= 0){
            er[5] = true
        }
        if (getJointinfo.joint_width === ""  || parseFloat(getJointinfo.joint_width) <= 0){
            er[6] = true
        }
        if (getJointinfo.plate_elasticity === ""  || parseFloat(getJointinfo.plate_elasticity) <= 0){
            er[7] = true
        }
        if (getJointinfo.strap_elasticity === ""  || parseFloat(getJointinfo.strap_elasticity) <= 0){
            er[8] = true
        }
        if (getJointinfo.fastener_elasticity === ""  || parseFloat(getJointinfo.fastener_elasticity) <= 0){
            er[9] = true
        }
        if (getJointinfo.flexibility === ""){
            er[10] = true
        }
        if (getJointinfo.elasticity === ""){
            er[11] = true
        }
        if (getJointinfo.hole_type === ""){
            er[12] = true
        }
        if (getJointinfo.fastener_type === ""){
            er[13] = true
        }
        if (getJointinfo.joint_type === "" && getJointinfo.flexibility === "Huth"){
            er[14] = true
        }
        if (Number(getJointinfo.fastener_combobox1) === -1 && getJointinfo.flexibility === "Swift") {
            er[15] = true
        }

        setJointInputError({...jointInputError,["load_stress_value"]:er[0],["fastener_rows"]:er[1],["strap_thickness"]:er[2],
            ["fastener_diameter"]:er[3],["row_spacing"]:er[4], ["plate_thickness"]: er[5],["joint_width"]:er[6],
            ["plate_elasticity"]:er[7],["strap_elasticity"]:er[8],["fastener_elasticity"]:er[9],["flexibility"]:er[10], ["elasticity"]:er[11],
            ["hole_type"]:er[12], ["fastener_type"]:er[13], ["joint_type"]: er[14],["fastener_combobox1"]:er[15],
        });

        if (!(er.includes(true))) {
            try {
                const response = await axios.post(auth().website + "/runprogram",
                    getJointinfo
                );

                let description3 = []
                if (getJointinfo.strap_stringer === "Strap") {
                    description3 = ['Fastener spring constant(in/lbf)', 'Plate spring constant (in/lbf)', 'Strap spring constant (in/lbf)',
                        'Fastener bearing load (in/lbf)', 'Plate bypass load (in/lbf)', 'Strap bypass load (lbf)']
                } else {
                    description3 = ['Fastener spring constant(in/lbf)', 'Plate spring constant (in/lbf)', 'Stringer spring constant (in/lbf)',
                        'Fastener bearing load (in/lbf)', 'Plate bypass load (in/lbf)', 'Stringer bypass load (lbf)']
                }

                setplotData({
                    "Bearing Load": {
                        "data": response.data['Bearing Load']['data'],
                        "label": "Bearing Load of Fasteners"
                    },
                    "Bearing Load/F x 100": {
                        "data": response.data['Bearing Load/F x 100']['data'],
                        "label": "Bearing Load of Fasteners/F %"
                    },
                    "Local Peak Stress": {
                        "data_Bar1": response.data['Local Peak Stress']['data_Bar1'],
                        "data_Bar2": response.data['Local Peak Stress']['data_Bar2'],
                        "label_Bar1": "Local Peak Stress in Plate",
                        "label_Bar2": "Local Peak Stress in" + ' ' + getJointinfo.strap_stringer
                    },
                    "Severity Factor": {
                        "data_Bar1": response.data['Severity Factor']['data_Bar1'],
                        "data_Bar2": response.data['Severity Factor']['data_Bar2'],
                        "label_Bar1": "Severity Factor in Plate",
                        "label_Bar2": "Severity Factor in" + ' ' + getJointinfo.strap_stringer
                    },
                    'Fastener spring constant(in/lbf)': response.data['Fastener spring constant(in/lbf)'],
                    'Plate spring constant (in/lbf)': response.data['Plate spring constant (in/lbf)'],
                    'Strap spring constant (in/lbf)': response.data['Strap spring constant (in/lbf)'],
                    'Stringer spring constant (in/lbf)': response.data['Strap spring constant (in/lbf)'],
                    'Fastener bearing load (in/lbf)': response.data['Fastener bearing load (in/lbf)'],
                    'Plate bypass load (in/lbf)': response.data['Plate bypass load (in/lbf)'],
                    'Strap bypass load (lbf)': response.data['Strap bypass load (lbf)'],
                    'Stringer bypass load (lbf)': response.data['Strap bypass load (lbf)'],
                    'Plate peak stress (ksi)': response.data['Plate peak stress (ksi)'],
                    'Strap peak stress (ksi)': response.data['Strap peak stress (ksi)'],
                    'Stringer peak stress (ksi)': response.data['Strap peak stress (ksi)'],
                    'Plate Severity Factor': response.data['Plate Severity Factor'],
                    'Strap Severity Factor': response.data['Strap Severity Factor'],
                    'Stringer Severity Factor': response.data['Strap Severity Factor'],

                    'Unfactored Fatigue Life Cycles Based on MMPDS': response.data['Unfactored Fatigue Life Cycles Based on MMPDS'],
                    'Fatigue Life Cycle with Scatter Factor, Method 1': response.data["Fatigue Life Cycle with Scatter Factor, Method 1"],
                    'Fatigue Life Cycle with Scatter Factor, Method 2': response.data['Fatigue Life Cycle with Scatter Factor, Method 2'],
                    "Final Fatigue Life Cycles": response.data["Final Fatigue Life Cycles"],
                    "Threshold Cycle with Scatter Factor, Method 1": response.data["Threshold Cycle with Scatter Factor, Method 1"],
                    "Threshold Cycle with Scatter Factor, Method 2": response.data["Threshold Cycle with Scatter Factor, Method 2"],
                    "Final Threshold Cycles": response.data["Final Threshold Cycles"],
                    "Fatigue Image": response.data["Fatigue Image"],

                });
                setResult(true);
                setState(
                    {
                        //labels: [1, 2, 3, 4],
                        labels: createArray(response.data['Bearing Load']['data'].length),
                        datasets: [
                            {
                                id: 1,
                                label: "Bearing Load of Fasteners",
                                backgroundColor: "#54576d",
                                borderColor: "#54576d",
                                borderWidth: 1,
                                data: response.data['Bearing Load']['data'],
                                barPercentage: 20,
                                barThickness: 20,
                            }
                        ]
                    }
                );

                setPlottype({
                    plot_type: "Bearing Load",
                    show_plot: true,
                    plot_description: description3
                });

            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
        else{
            setJointAlertOpen(true);

        }
    };

    const BacktoMain = () => {
        setResult(false);
    }

    const [getPlottype, setPlottype] = useState({
        plot_type: "Bearing Load",
        show_plot: false,
        plot_description: [],
    })
    const handlePlotType = (event) => {
        let st_value = getJointinfo.strap_stringer
        if (event.target.value === "Fatigue Life"){
            setPlottype({
                plot_type: event.target.value,
                show_plot: false,
                plot_description: [
                'Unfactored Fatigue Life Cycles Based on MMPDS',
                'Fatigue Life Cycle with Scatter Factor, Method 1',
                'Fatigue Life Cycle with Scatter Factor, Method 2',
                "Final Fatigue Life Cycles",
                "Threshold Cycle with Scatter Factor, Method 1",
                "Threshold Cycle with Scatter Factor, Method 2",
                "Final Threshold Cycles"]
            });
        }
        else{
            if (event.target.value === "Bearing Load" || event.target.value === "Bearing Load/F x 100"){
                let description = []
                if (getJointinfo.strap_stringer === "Strap") {
                    description = ['Fastener spring constant(in/lbf)', 'Plate spring constant (in/lbf)','Strap spring constant (in/lbf)',
                        'Fastener bearing load (in/lbf)','Plate bypass load (in/lbf)','Strap bypass load (lbf)']
                }else{
                    description = ['Fastener spring constant(in/lbf)', 'Plate spring constant (in/lbf)','Stringer spring constant (in/lbf)',
                        'Fastener bearing load (in/lbf)','Plate bypass load (in/lbf)','Stringer bypass load (lbf)']
                }
                setState(
                    {
                        labels: createArray(plotData[event.target.value]["data"].length),
                        datasets: [
                            {
                                id: 1,
                                label: plotData[event.target.value]["label"],
                                backgroundColor:  "#54576d",
                                borderColor: "#54576d",
                                borderWidth: 1,
                                data: plotData[event.target.value]["data"],
                                barPercentage: 20,
                                barThickness: 20,
                            }
                        ]
                    }
                );
                setPlottype({
                    plot_type: event.target.value,
                    show_plot: true,
                    plot_description : description
                });
            }else{
                let description1 = []
                setState(
                    {
                        labels: createArray(plotData[event.target.value]["data_Bar1"].length),
                        datasets: [
                            {
                                id: 1,
                                label: plotData[event.target.value]["label_Bar1"],
                                backgroundColor: "#54576d",
                                borderColor: "#54576d",
                                borderWidth: 1,
                                data: plotData[event.target.value]["data_Bar1"],
                                barPercentage: 20,
                                barThickness: 20,
                            },
                            {
                                id: 2,
                                label: plotData[event.target.value]["label_Bar2"],
                                backgroundColor: red[800],
                                borderColor: red[800],
                                borderWidth: 1,
                                data: plotData[event.target.value]["data_Bar2"],
                                barPercentage: 20,
                                barThickness: 20,
                            },
                        ]
                    }
                );
                if (event.target.value === "Local Peak Stress"){

                    if (getJointinfo.strap_stringer === "Strap") {
                        description1 = ['Plate peak stress (ksi)', 'Strap peak stress (ksi)']
                    }else{
                        description1 = ['Plate peak stress (ksi)', 'Stringer peak stress (ksi)']
                    }
                    setPlottype({
                        plot_type: event.target.value,
                        show_plot: true,
                        plot_description : description1
                    });
                }else{

                    if (getJointinfo.strap_stringer === "Strap") {
                       description1 = ['Plate Severity Factor','Strap Severity Factor']
                    }else{
                       description1 = ['Plate Severity Factor','Stringer Severity Factor']
                    }
                    setPlottype({
                        plot_type: event.target.value,
                        show_plot: true,
                        plot_description : description1
                    });
                }
            }

        }
    }

    const NewJointProject = () => {
        setJointinfo({
            unit:"1",
            flexibility: "",
            elasticity: "",
            fatigue: "No",
            configuration: "1",
            load_stress_value:"",
            load_stress:"Load",
            load_stress_symbole:", F",
            fastener_rows:"",
            strap_thickness:"",
            fastener_diameter:"",
            row_spacing:"",
            plate_thickness:"",
            joint_width:"",
            hole_type:"",
            fastener_type:"",
            joint_type:"",
            plate_combobox1: -1,
            plate_combobox2: -1,
            plate_combobox3: -1,
            strap_combobox1: -1,
            strap_combobox2: -1,
            strap_combobox3: -1,
            fastener_combobox1: -1,
            fastener_combobox2: -1,
            fastener_combobox3: -1,
            plate_elasticity:"",
            strap_elasticity:"",
            fastener_elasticity:"",
            showJointInfo:true,
            strap_stringer: "strap",
            alloy: "",
            temper: "",
        });
        setJointInputError({
            load_stress_value: false,
            fastener_rows: false,
            strap_thickness: false,
            fastener_diameter: false,
            row_spacing: false,
            plate_thickness: false,
            joint_width: false,
            plate_elasticity: false,
            strap_elasticity: false,
            fastener_elasticity: false,
            flexibility: false,
            elasticity: false,
            hole_type: false,
            fastener_type: false,
            joint_type: false,
            fastener_combobox1: false,
        });

        setImgSrc("SingleLap")
        setDrawerOpenJoint(false);
        setResult(false);
    }
    const SaveJointProject = () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(getJointinfo)], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "JointLoad_input.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setDrawerOpenJoint(false);
    }
    const LoadJointProject = (event) => {
        NewJointProject()
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            var fr = new FileReader()
            var textFile = /text.*/;
            if (event.target.files[0].type.match(textFile)) {
                fr.onload=function(){
                    var res = JSON.parse(fr.result);
                    if (res["fatigue"] !== getJointinfo.fatigue) {
                        setError("");
                        let values = {fatigue: res["fatigue"]}
                        async function fetchData() {
                            try {
                                const response = await axios.post(auth().website + "/JointMaterial", values
                                );
                                setJointMaterial(response.data);
                                setJointinfo(res);
                            } catch (err) {
                                if (err && err instanceof AxiosError)
                                    setError(err.response?.data.message);
                                else if (err && err instanceof Error) setError(err.message);
                            }
                        }
                        fetchData();
                    }
                    else{
                        setJointinfo(res);
                    }
                }
            } else {
                alert("It doesn't seem to be a text file!");
            }
            fr.readAsText(event.target.files[0]);

        } else {
            alert("Your browser is too old to support HTML5 File API");
        }
        ;
       // setDrawerOpenJoint (false);
    }





  return (
        <MainContext.Provider
            value={{jointAlertOpen, setJointAlertOpen, drawerOpenJoint, setDrawerOpenJoint, result, LoadJointProject, NewJointProject, SaveJointProject, imgSrc, state, getJointinfo,setJointInfo, getJointMaterial, runJoint, getPlottype, handlePlotType, plotData, BacktoMain, jointInputError, setJointInputError}}
        >
            <MainLayout>
                <SidebarContain>
                    <Sidebar />
                </SidebarContain>
                <DrawerActionButton />
                <PagesContainer>
                    {(result == true)? <ResultPage /> : <MainPage />}
                </PagesContainer>
            </MainLayout>
        </MainContext.Provider>


  );
};

export default Joint;
