import {Box, Typography} from "@mui/material";

const DentManual = () => {
    return(
        <Box sx = {{mt:10, ml: 5, mr: 5}}>
            <Box >
                <Typography sx = {{fontFamily: "impact, sans-serif", fontSize: "2.0rem", pl:1, color: "#fff"}}>
                    Dent
                </Typography>
                <Typography align="justify" sx = {{fontFamily: "sans-serif", color: "#fff", fontWeight: 700 , fontSize: "0.9rem", pl:1, borderBottom: "1px solid "}}>
                    Dented Fuselage Skin Fatigue Life Calculation Program
                </Typography>
            </Box>
            <Box sx = {{p: 3, fontStyle: 'italic', color: "#fff"}}>
                <Typography align="justify" sx = {{ color: "#fff", }}>
                    This program is designed to calculate the fatigue life of dented fuselage skin. Through analysis of factors such as the location and configuration of the dent, the program determines a safe threshold for continued service of the aircraft, allowing for cost and time savings by avoiding the need for permanent repairs until necessary.
                </Typography>
            </Box>
        </Box>



    );
}
export default DentManual;