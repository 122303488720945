import {Box, Button, Alert, Typography, Collapse, AlertTitle} from "@mui/material";

import {useContext} from "react";
import MainContext from "../../context";
import {Material, General, Header, Geometry, ResultPage}  from "./index";
import {Footer} from "../components";
import {useAuthUser} from "react-auth-kit";
import axios, {AxiosError} from "axios";

const MainPage = () => {
    const {runJoint, jointAlertOpen,setJointAlertOpen} = useContext(MainContext);

    return(
        <>
            <div
                style={{
                    display: jointAlertOpen ? "block" : "none",
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Alert severity="warning" >
                    <AlertTitle>Warning</AlertTitle>
                    Incorrect input value — <strong> check it out! </strong>
                    <Box sx={{mt: 1}} id = "alert">
                        <Button color="inherit" size="small" onClick={() => {setJointAlertOpen(false);}}>
                            OK
                        </Button>
                    </Box>
                </Alert>
            </div>

            <Box
                sx={{
                    height: "100vh",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Header />
                <Box sx = {{
                    height: "95%",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: "whitesmoke", border: "1px solid whitesmoke" ,borderRadius: 5,mt: 2.5, ml: 2.5,mr: 2.5, p: 2, mb:2}} >

                    <General  />
                    <Geometry />
                    <Material />
                    <General  />
                    <Geometry />
                    <Material />
                    <Box  sx = {{textAlign: "center", mt: 1,}}>
                        <Button variant="contained" value="RunJoint"   onClick={runJoint}
                                sx = {{
                                    my: 0.5,
                                    mx:1,
                                    width: "30%", fontWeight: 700,
                                }}> RUN PROGRAM </Button>
                    </Box>



                </Box>
                <Footer />

            </Box>

        </>

    );
}
export default MainPage;