import { useState, useEffect } from "react";
import MainLayout from "../layouts/MainLayout";
import { Sidebar } from "../components/sidebar";
import PagesContainer from "./PagesContainer";
import SidebarContain from "./SidebarContain";
import MainContext from "../context";
import DrawerActionButton from "../components/Drawer/DrawerActionButton";
import MainPage from "../pages/components/MainPage";
import ResultPage from "../pages/components/ResultPage";
import FullGuide from "../pages/components/FullGuide";
import axios, {AxiosError} from "axios";
import {useAuthUser} from 'react-auth-kit';

const Dent = () => {
    const auth = useAuthUser()
    document.addEventListener("wheel", function(event) {
        if (document.activeElement.type === "number" &&
            document.activeElement.classList.contains("noscroll")) {
            document.activeElement.blur();
        }
    });

    const [alertOpen, setAlertOpen] = useState(false);
    const [guides, setGuides] = useState(false);

    const fullGuideLine = () => {
        setGuides(true);
    }

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [getDentinfo, setDentinfo] = useState({
        unit: "1",
        width: "",
        depth: "",
        delta_pressure: "",
        radius: "",
        thickness: "",
        criticality: "",
        reworked: "",
        material: -1,
        dentImg:"AL2024T33"
    });
    const [getOutput, setOutput] = useState("");
    const [result,setResult] = useState(false);
    const [dentInputError, setDentInputError] = useState({
        width: false,
        depth: false,
        delta_pressure: false,
        radius: false,
        thickness: false,
        material: false,
        criticality:false,
        reworked:false,
    });
    const handleDentInfo = (event) => {
        if (event.target.name === "material"){
            if (event.target.value === "1" || event.target.value === "2"){
                setDentinfo({...getDentinfo,["dentImg"]:"AL2024T33", [event.target.name]: event.target.value,});
            }else{
                setDentinfo({...getDentinfo,["dentImg"]:"AL7075T63", [event.target.name]: event.target.value,});
            }
        }else{
            setDentinfo({...getDentinfo, [event.target.name]: event.target.value,});
        }
    }

    const [Description,setDescription] = useState([])
    const [error, setError] = useState("");
    const runDent = async (e) => {
        e.preventDefault()
        let er = [false,false,false,false,false,false, false,false]
        let mat = parseInt(getDentinfo.material, 10)
        let depth  = parseFloat(getDentinfo.depth)
        let val1  = 0.3
        let val2 = 0.15
        if (getDentinfo.unit === "2") {
            val2  = 3.81
            val1 = 7.62
        }


        if (getDentinfo.width === ""  || parseFloat(getDentinfo.width) <= 0){
            er[0] = true
        }
        if ((mat === 1 && depth > 0.3) || (mat === 2 && depth > 0.3) || (mat=== 3 && depth > 0.15) || (mat === 4 && depth > 0.15) || (getDentinfo.depth === "")  || (parseFloat(getDentinfo.depth) <= 0) )                 {
            //document.getElementById("inputDepth").style.border = "solid 1px #c1392f";
            er[1] = true
        }
       if (getDentinfo.delta_pressure === ""  || parseFloat(getDentinfo.delta_pressure) <= 0){
            er[2] = true
        }
        if (getDentinfo.radius === ""  || parseFloat(getDentinfo.radius) <= 0){
            er[3] = true
        }
        if (getDentinfo.thickness === ""  || parseFloat(getDentinfo.thickness) <= 0){
            er[4] = true
        }
        if (getDentinfo.material === -1){
            er[5] = true
        }
        if (getDentinfo.criticality === ""){
            er[6] = true
        }
        if (getDentinfo.reworked === ""){
            er[7] = true
        }
        setDentInputError({...dentInputError,["width"]:er[0],["depth"]:er[1],["delta_pressure"]:er[2],["radius"]:er[3], ["thickness"]: er[4],["material"]:er[5],["criticality"]:er[6],["reworked"]:er[7], });
         if (!(er.includes(true))){
            setError("");
            try {
                const response = await axios.post(auth().website + "/Dentprogram",
                    getDentinfo
                );

                setOutput(response.data)
                var length = Object.keys(response.data).length;
                if (length > 1) {
                    setDescription(["Unfactored Fatigue Life Cycles Based on MMPDS", "Fatigue Life Cycle with Scatter Factor, Method 1",
                        "Fatigue Life Cycle with Scatter Factor, Method 2", "Final Fatigue Life Cycle"]);
                }else{
                    setDescription(["Warning"]);
                }
                setResult(true);
            } catch (err) {
                if (err && err instanceof AxiosError)
                    setError(err.response?.data.message);
                else if (err && err instanceof Error) setError(err.message);
            }
        }
         else{
             setAlertOpen(true);
         }
    };
    const NewProject = () => {
        setDentinfo({
            unit: "1",
            width: "",
            depth: "",
            delta_pressure: "",
            radius: "",
            thickness: "",
            criticality: "",
            reworked: "",
            material: -1,
            dentImg:"AL2024T33",
        });

        setDentInputError({
            width: false,
            depth: false,
            delta_pressure: false,
            radius: false,
            thickness: false,
            material: false,
            criticality:false,
            reworked:false,
        });

        setDrawerOpen(false);
        setResult(false);
    }
    const SaveProject = () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(getDentinfo)], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "Dent.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        setDrawerOpen(false);
    }
    const LoadProject = (event) => {
        NewProject()
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            var fr = new FileReader()
            var textFile = /text.*/;
            if (event.target.files[0].type.match(textFile)) {
                fr.onload=function(){
                    var res = JSON.parse(fr.result);
                    setDentinfo(res);
                }
            } else {
                alert("It doesn't seem to be a text file!");
            }
            fr.readAsText(event.target.files[0]);

        } else {
            alert("Your browser is too old to support HTML5 File API");
        }
        ;
    }
    const BacktoMainDent = () => {
        setResult(false);
        setGuides(false);
    }
    return (
            <MainContext.Provider
                value={{
                    drawerOpen,
                    setDrawerOpen,
                    getDentinfo,
                    handleDentInfo,
                    runDent,
                    getOutput,
                    NewProject,
                    SaveProject,
                    LoadProject,
                    BacktoMainDent,
                    dentInputError,
                    setDentInputError,
                    Description,
                    alertOpen,
                    setAlertOpen,
                    guides,
                    setGuides,
                    fullGuideLine,
                }}
            >
                <MainLayout>
                    <SidebarContain>
                        <Sidebar/>
                    </SidebarContain>
                    <DrawerActionButton/>
                    <PagesContainer>
                        {(result == true)? <ResultPage /> : (guides == true ? <FullGuide /> : <MainPage />) }
                    </PagesContainer>

                </MainLayout>
            </MainContext.Provider>
        );
}
export default Dent;
