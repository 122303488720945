import {Box, Button, Divider, Typography} from "@mui/material";
import Header from "./Header";
import {Footer} from "./index";
import {useContext} from "react";
import MainContext from "../../context";
import {grey} from "@mui/material/colors";

const FullGuide = () => {
    const {BacktoMainDent} = useContext(MainContext);
    return(
        <Box
            sx={{
                height: "100vh",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header />
            <Box sx = {{height: "70%",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: "whitesmoke", border: "1px solid whitesmoke" ,borderRadius: 5,mt: 2.5, ml: 2.5,mr: 2.5, p: 2}} >
                <Box sx = {{ fontSize: "1.2rem",  mt: 2, ml: 2}} color = {grey[600]} >
                    <Typography  variant="subtitle1"  > Guideline </Typography>
                    <Divider variant="middle" sx = {{borderColor:grey[400]}} />

                    <Box sx = {{mt: 3, textAlign: "center"}}>
                        <img
                            src={require("../../assets/DentImg/dent.png")}
                            alt="SCA"
                            style={{width: "50%"}}
                        />
                    </Box>
                    <Typography align="justify" variant="subtitle2" sx = {{p:5, fontStyle: 'italic', fontWeight: "bold" }}  >
                        "The critical region is defined when the edge of the dent is within the location of  ½ inch and ¾  inch dimensions from the underlying stiffener fastener, as defined in the figures. Analysis can be continued provided that there is no deformation of the underlying structure, minor deformation of the free flange maybe acceptable."
                    </Typography>
                </Box>

                <Box  sx = {{textAlign: "left", mt: 'auto',  }}>
                    <Button  variant="contained" value="RunJoint" onClick={BacktoMainDent}
                             sx = {{my: 0.5,mx:3, width: 250, fontWeight:700}}>
                        BACK TO PROJECT
                    </Button>
                </Box>

            </Box>
            <Footer />
        </Box>

    );
}

export default FullGuide;
