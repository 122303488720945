//import SidebarContent from "../sidebar/SidebarContent";
import {SidebarContent} from "../sidebar";
import {Drawer} from "@mui/material";
import {useContext} from "react";
import MainContext from "../../context";
import {useAuthUser} from "react-auth-kit";
const SidebarDrawer = () => {
    const {drawerOpen, drawerOpenJoint, setDrawerOpen, setDrawerOpenJoint} = useContext(MainContext)
    const auth = useAuthUser()
    return(
        <Drawer
            open={(auth().Soft === "Dent")? drawerOpen: drawerOpenJoint}
            variant="temporary"
            onClose={() => {(auth().Soft === "Dent")? setDrawerOpen(false):setDrawerOpenJoint(false)}}

            sx={{
                "& .MuiDrawer-paper": {
                    width: 100,
                    overflowY: "scroll",
                    overflowX: "hidden",
                },
                display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                },

            }}
        >
            <SidebarContent  />
        </Drawer>
    );
}
export default SidebarDrawer;