import { createTheme } from "@mui/material/styles";
import {blue, grey, red} from "@mui/material/colors";

//NOTE Create Custom Theme

export const theme = createTheme({
  direction: "ltr",
  palette: {
    mode: "dark",
    primary: {
      main: red[900],
    },
    secondary: {
      main: grey[500],
    },
/*    inherit:{
      main: red[900],
    }*/
  },
  typography: {
    fontFamily: "arial, roboto",
    h5: {
      //color: theme.palette.secondary.main
      color: grey[700]
    },
    h6:{
      color: grey[500]
    },
    body1:{
      color: grey[600]
    },
    subtitle1:{
      color: red[900],
      marginLeft: 15,
      fontWeight: 700,
    },
  },
  label: {
    fontFamily: "arial, roboto",
    //fontSize : 28,
  },
  input: {
    fontFamily: "arial, roboto",
    fontSize : 13,
    color: red[600],
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "dashed" },
          style: {
            textTransform: "none",
            border: `2px dashed #30323f`,
          },
        },
        {
          props: { variant: "dashed", color: "secondary" },
          style: {
            border: `4px dashed ${red[900]}`,
          },
        },
      ],
    },
  },

});

/*
const theme = createMuiTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: orange[700]
      }
    },
    MuiTab: {
      root: {
        "&:hover": {
          backgroundColor: pink[100],
          color: pink[700]
        }
      },
      selected: {
        backgroundColor: orange[100],
        color: orange[700],
        "&:hover": {
          backgroundColor: green[100],
          color: green[700]
        }
      }
    }
  }
});
*/

