import {useContext} from "react";
import MainContext from "../../context";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Divider, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

const Geometry = () => {
    const {imgSrc, getJointinfo, setJointInfo, jointInputError} = useContext(MainContext);
    return(
        <Box sx = {{mt: 7}}>
            <Typography  variant="subtitle1" > GEOMETRY INFORMATION </Typography>
            <Divider variant="middle"  sx = {{borderColor:grey[400]}} />
            <Box sx = {{ml : 5, mt: 2}}>
                <Grid container  sx = {{mb: 3, fontSize: "1.2rem"}} color = {grey[600] }>
                    <Grid xs= {8}>
                        <Grid container >
                            <Grid xs= {6} >
                                <Box>
                                    <Typography> Configuration </Typography>
                                </Box>
                            </Grid>
                            <Grid xs= {6} >
                                <Box>
                                    <Typography > {getJointinfo.load_stress} {getJointinfo.load_stress_symbole} </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid xs= {6} >
                                <Box>
                                    <select
                                        name="configuration"
                                        value={getJointinfo.configuration}
                                        onChange={setJointInfo}
                                        className="selectclass"
                                        required={true}
                                    >
                                        <option value="">Select Configuration</option>
                                        <option value = "1"> SingleLap </option>
                                        <option value = "2"> DoubleLap </option>
                                        <option value = "3"> HardPoint </option>
                                        <option value = "4"> RunOut </option>
                                    </select>
                                </Box>
                            </Grid>
                            <Grid xs= {6} >
                                <Box>
                                    <input
                                        name="load_stress_value"
                                        type="number"
                                        value={getJointinfo.load_stress_value}
                                        onChange={setJointInfo}
                                        className={jointInputError.load_stress_value ? "inputclass form-input-fail noscroll" : "inputclass noscroll"}
                                        required={true}
                                        onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid xs= {6} >
                                <Box  sx = {{mt: 3}}>
                                    <Typography > Number of Fastener Rows, n</Typography>
                                </Box>
                            </Grid>
                            <Grid xs= {6} >
                                <Box sx = {{mt: 3}}>
                                    <Typography > Fastener Diameter, D </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid xs= {6} >
                                <Box>
                                    <input
                                        name="fastener_rows"
                                        type="number"
                                        value={getJointinfo.fastener_rows}
                                        onChange={setJointInfo}
                                        className={
                                            jointInputError.fastener_rows ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                        }
                                        required={true}
                                        onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                        min={0}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs= {6} >
                                <Box>
                                    <input
                                        name="fastener_diameter"
                                        type="number"
                                        value={getJointinfo.fastener_diameter}
                                        onChange={setJointInfo}
                                        className={
                                            jointInputError.fastener_diameter ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                        }
                                        required={true}
                                        onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                        min={0}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid xs= {6} >
                                <Box sx = {{mt: 3}}>
                                    <Typography > Plate Thickness, tp </Typography>

                                </Box>
                            </Grid>
                            <Grid xs= {6} >
                                <Box sx = {{mt: 3}}>
                                    <Typography > {getJointinfo.strap_stringer} {" "} Thickness, ts </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid xs= {6} >
                                <Box >
                                    <input
                                        name="plate_thickness"
                                        type="number"
                                        value={getJointinfo.plate_thickness}
                                        onChange={setJointInfo}
                                        className={
                                            jointInputError.plate_thickness ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                        }
                                        required={true}
                                        onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                        min={0}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs= {6} >
                                <Box>
                                    <input
                                        name="strap_thickness"
                                        type="number"
                                        value={getJointinfo.strap_thickness}
                                        onChange={setJointInfo}
                                        className={
                                            jointInputError.strap_thickness ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                        }
                                        required={true}
                                        onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                        min={0}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid xs= {6} >
                                <Box sx = {{mt: 3}}>
                                    <Typography > Row Spacing of Fasteners, L </Typography>

                                </Box>
                            </Grid>
                            <Grid xs= {6} >
                                <Box sx = {{mt: 3}}>
                                    <Typography > Joint Width, W </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid xs= {6} >
                                <Box>
                                    <input
                                        name="row_spacing"
                                        type="number"
                                        value={getJointinfo.row_spacing}
                                        onChange={setJointInfo}
                                        className={
                                            jointInputError.row_spacing ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                        }
                                        required={true}
                                        onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                        min={0}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs= {6} >
                                <Box>
                                    <input
                                        name="joint_width"
                                        type="number"
                                        value={getJointinfo.joint_width}
                                        onChange={setJointInfo}
                                        className={
                                            jointInputError.joint_width ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                        }
                                        required={true}
                                        onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                        min={0}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid xs= {4} >
                        <Box sx = {{mt:2, mr: 1, textAlign: "center"}} className= "img-container">
                            <img
                                src = {require(`../../assets/${getJointinfo.load_stress}/${imgSrc}.png`)}
                                alt = "JointLoad"
                                style={{width: "100%", }}
                            />
                        </Box>

                    </Grid>
                </Grid>

                <Box sx = {{mt: 5, backgroundColor: "#eeeeee" }}>
                    <Grid container  sx = {{fontSize: "1.2rem" }} color = {grey[600] }>
                        <Grid xs ={1}></Grid>
                        <Grid xs= {3}>
                            <Typography sx ={{fontWeight : 700, mt : 3}}> Type of Hole</Typography>
                        </Grid>
                        <Grid xs= {3}>
                            <Typography sx ={{fontWeight : 700, mt : 3}}> Type of Fastener</Typography>
                        </Grid>
                        <Grid xs= {3}>
                            <Typography sx ={{fontWeight : 700, mt : 3}}> Joint Type</Typography>
                        </Grid>
                        <Grid xs ={1} ></Grid>
                        <Grid xs ={1} sx = {{backgroundColor: "whitesmoke"}}></Grid>
                    </Grid>

                    <Grid container  sx = {{fontSize: "1.2rem" }} color = {grey[600] }>
                        <Grid xs ={1}></Grid>
                        <Grid xs= {3}>
                            <Box sx = {{mt: 1.5,ml: 1.5}}>
                                <Typography> <input checked={getJointinfo.hole_type === 'Standard Hole Drilled'} onChange={setJointInfo} className={jointInputError.hole_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Standard Hole Drilled" name="hole_type" /> Standard Hole Drilled </Typography>
                                <Typography> <input checked={getJointinfo.hole_type === 'Broached or Reamed'} onChange={setJointInfo} className={jointInputError.hole_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Broached or Reamed" name="hole_type" /> Broached or Reamed </Typography>
                                <Typography> <input checked={getJointinfo.hole_type === 'Cold Worked Holes'} onChange={setJointInfo} className={jointInputError.hole_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Cold Worked Holes" name="hole_type" />  Cold Worked Holes </Typography>
                            </Box>
                        </Grid>
                        <Grid xs= {3}>
                            <Box sx = {{mt: 1.5,ml: 2, mb:1.5}} >
                                <Typography> <input checked={getJointinfo.fastener_type === 'Lock Bolt (Steel)'} onChange={setJointInfo} className={jointInputError.fastener_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Lock Bolt (Steel)" name="fastener_type" /> Lock Bolt (Steel) </Typography>
                                <Typography> <input checked={getJointinfo.fastener_type === 'Rivets'} onChange={setJointInfo} className={jointInputError.fastener_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Rivets" name="fastener_type" /> Rivets </Typography>
                                <Typography> <input checked={getJointinfo.fastener_type === 'Threaded Bolts'} onChange={setJointInfo} className={jointInputError.fastener_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Threaded Bolts" name="fastener_type" /> Threaded Bolts </Typography>
                                <Typography> <input checked={getJointinfo.fastener_type === 'Taper-Lok'} onChange={setJointInfo} className={jointInputError.fastener_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Taper-Lok" name="fastener_type" />   Taper-Lok </Typography>
                                <Typography> <input checked={getJointinfo.fastener_type === 'Hi-Lok'} onChange={setJointInfo} className={jointInputError.fastener_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Hi-Lok" name="fastener_type" /> Hi-Lok </Typography>
                            </Box>
                        </Grid>
                        <Grid xs= {3}>
                            <Box  sx = {{mt: 1.5,ml: 2, mb:1.5}} value={getJointinfo.joint_type} onChange={setJointInfo} id = "Joint_type" style={{ display: getJointinfo.showJointInfo ? "block" : "none" }}    >
                                <Typography> <input checked={getJointinfo.joint_type === 'Bolted Metallic'} onChange={setJointInfo} className={jointInputError.joint_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Bolted Metallic" name="joint_type" /> Bolted Metallic </Typography>
                                <Typography> <input checked={getJointinfo.joint_type === 'Riveted Metallic'} onChange={setJointInfo} className={jointInputError.joint_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Riveted Metallic" name="joint_type" /> Riveted Metallic </Typography>
                                <Typography> <input checked={getJointinfo.joint_type === 'Bolted Graphite/Epoxy'} onChange={setJointInfo} className={jointInputError.joint_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Bolted Graphite/Epoxy" name="joint_type" /> Bolted Graphite/Epoxy </Typography>
                                <Typography> <input checked={getJointinfo.joint_type === 'Bolted Metal & Graph/Epoxy'} onChange={setJointInfo} className={jointInputError.joint_type ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Bolted Metal & Graph/Epoxy" name="joint_type" /> Bolted Metal & Graph/Epoxy </Typography>
                            </Box>
                            <Box sx = {{mt: 1.5,ml: 2, mb:1.5}} style={{ display: getJointinfo.showJointInfo ? "none" : "block" }} >
                                Not Available
                            </Box>
                        </Grid>
                        <Grid xs ={1} ></Grid>
                        <Grid xs ={1} sx = {{backgroundColor: "whitesmoke"}}></Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
export default Geometry;
