import {Box, Typography} from "@mui/material";
const LoginManual = () => {
    return(
        <Box sx = {{ color: "#fff", height: "100%",  ml:5, mr:5,
            overflowY: "scroll",
            display: 'flex',
            flexDirection: 'column',

        }} >
            <Box sx = {{mt: "60%",
                }}>
                <Box sx = {{textAlign: "center",color: "#ffffff", borderBottom: "1px solid #fff"}}>
                    <Typography sx = {{fontFamily: "impact, sans-serif", fontSize: "1.75rem", display: "inline-block" , color: "#fff"}}>
                        WELCOME {"  "}
                    </Typography>
                </Box>
                <Box sx = {{display: "block", m: 2, justifyContent:"stretch", }}>
                    <Typography align="justify" sx = {{justifyContent:"left", fontStyle: 'italic', fontSize: "1.0rem", pl:1, color: "#fff"}}>
                        SCA web applications are tailored to provide comprehensive solutions for aviation professionals, offering accuracy and efficiency in managing critical aspects of structural integrity and safety.
                        <br />

                    </Typography>
                </Box>
            </Box>

        </Box>
    );
}
export default LoginManual;