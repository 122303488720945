import {Box, Divider, Typography} from "@mui/material";
import {grey, red} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import {useContext} from "react";
import MainContext from "../../context";

const MaterialInfo = () => {
    const {getDentinfo, handleDentInfo, dentInputError} = useContext(MainContext);
    return(
        <Box sx = {{mt: 5,}}>
            <Typography  variant="subtitle1" > MATERIAL INFORMATION </Typography>
            <Divider variant="middle" sx = {{borderColor:grey[400]}} />
            <Grid container>
                <Grid xs={12}
                      sm={8}
                      md={4}
                      lg={4}
                      xl={4}
                >
                    <Box sx = {{ml : 5, mt: 2}}>
                        <Typography  sx = {{typography: {xl: 'body1', lg: "subtitle2", md: "subtitle2" , sm: "subtitle2", xs: "caption" }}} >
                             Select Skin Material
                        </Typography>
                        <select
                            name="material"
                            value={getDentinfo.material}
                            onChange={handleDentInfo}
                            className={
                                dentInputError.inputDepth ? "selectclass form-input-fail" : "selectclass"
                            }
                            //className="selectclass"
                            required={true}
                        >
                            <option value=""   >Select Material</option>
                            <option value = "1"> AL 2024-T3 BARE </option>
                            <option value = "2"> AL 2024-T3 CLAD </option>
                            <option value = "3"> AL 7075-T6 BARE </option>
                            <option value = "4"> AL 7075-T6 CLAD </option>
                        </select>
                    </Box>

                </Grid>

            </Grid>

        </Box>
    );
}
export default MaterialInfo;