import {grey} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import {Button} from "@mui/material";
const SidebarContain = ({children}) => {
    return (
        <Grid
            xs={0}
            sm={0}
            md={1}
            lg={1}
            xl={1}
            sx={{ backgroundColor: "#30323f" ,
                height: "100vh",
                overflowY: "scroll",
                overflowX: "hidden",
                position: "relative",
            }}
        >

            {children}
        </Grid>
    );
};

export default SidebarContain;