import {Box, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

const Header = () => {
    return (
    <Box sx = {{backgroundColor: "whitesmoke" , textAlign: "center", p: 2, boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    }}>
        <Typography color={grey[700]}  sx={{typography: { sm: 'h5', xs: 'h6' }, }}   className="appTitle"  > DENT </Typography>
        <Typography sx={{typography: { md: 'h6', sm: 'body2' }, }} > Dented Fuselage Skin Fatigue Life Calculation Program  </Typography>
    </Box>
    );
}
export default Header;