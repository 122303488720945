import { useState } from "react";

import SidebarContent from "./SidebarContent";
import SidebarDrawer from "../Drawer/SidebarDrawer"

const Sidebar = () => {

    return(
        <>

            <SidebarContent />
            <SidebarDrawer />
        </>
    );
};

export default Sidebar;
