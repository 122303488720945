import {useContext} from "react";
import MainContext from "../../context";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Divider, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";

const Material = () => {
    const {getJointinfo, setJointInfo, getJointMaterial, jointInputError} = useContext(MainContext);
    const plateRenderSwitch1 = () => {
        switch(parseInt(getJointinfo.plate_combobox1)) {
            case 0:
                return (
                    <>
                        {getJointMaterial[7].map((material) => (
                            <option key = {material} value={getJointMaterial[7].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 1:
                return (
                    <>
                        {getJointMaterial[9].map((material) => (
                            <option key = {material} value={getJointMaterial[9].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 2:
                return (
                    <>
                        {getJointMaterial[11].map((material) => (
                            <option key = {material} value={getJointMaterial[11].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
        }
    }
    const plateRenderSwitch2 = () => {
        switch(parseInt(getJointinfo.plate_combobox1)) {
            case 0:
                return (
                    <>
                        {getJointMaterial[8][getJointinfo.plate_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[8][getJointinfo.plate_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 1:
                return (
                    <>
                        {getJointMaterial[10][getJointinfo.plate_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[10][getJointinfo.plate_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 2:
                return (
                    <>
                        {getJointMaterial[12][getJointinfo.plate_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[12][getJointinfo.plate_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
        }
    }
    const strapRenderSwitch1 = () => {
        switch(parseInt(getJointinfo.strap_combobox1)) {
            case 0:
                return (
                    <>
                        {getJointMaterial[1].map((material) => (
                            <option key = {material} value={getJointMaterial[1].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 1:
                return (
                    <>
                        {getJointMaterial[3].map((material) => (
                            <option key = {material} value={getJointMaterial[3].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 2:
                return (
                    <>
                        {getJointMaterial[5].map((material) => (
                            <option key = {material} value={getJointMaterial[5].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
        }
    }
    const strapRenderSwitch2 = () => {
        switch(parseInt(getJointinfo.strap_combobox1)) {
            case 0:
                return (
                    <>
                        {getJointMaterial[2][getJointinfo.strap_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[2][getJointinfo.strap_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 1:
                return (
                    <>
                        {getJointMaterial[4][getJointinfo.strap_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[4][getJointinfo.strap_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 2:
                return (
                    <>
                        {getJointMaterial[6][getJointinfo.strap_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[6][getJointinfo.strap_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
        }
    }
    const fastenerRenderSwitch1 = () => {
        switch(parseInt(getJointinfo.fastener_combobox1)) {
            case 0:
                return (
                    <>
                        {getJointMaterial[13].map((material) => (
                            <option key = {material} value={getJointMaterial[13].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 1:
                return (
                    <>
                        {getJointMaterial[15].map((material) => (
                            <option key = {material} value={getJointMaterial[15].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 2:
                return (
                    <>
                        {getJointMaterial[17].map((material) => (
                            <option key = {material} value={getJointMaterial[17].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
        }
    }
    const fastenerRenderSwitch2 = () => {
        switch(parseInt(getJointinfo.fastener_combobox1)) {
            case 0:
                return (
                    <>
                        {getJointMaterial[14][getJointinfo.fastener_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[14][getJointinfo.fastener_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 1:
                return (
                    <>
                        {getJointMaterial[16][getJointinfo.fastener_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[16][getJointinfo.fastener_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
            case 2:
                return (
                    <>
                        {getJointMaterial[18][getJointinfo.fastener_combobox2].map((material) => (
                            <option key = {material} value={getJointMaterial[18][getJointinfo.fastener_combobox2].indexOf(material)}>
                                {material}
                            </option>
                        ))}
                    </>
                );
        }
    }

    return(
        <Box sx = {{mt: 7, mb: 5}} >
            <Typography  variant="subtitle1"   > MATERIAL INFORMATION </Typography>
            <Divider variant="middle"  sx = {{borderColor:grey[400]}} />
            <Box sx = {{ml : 5}}>

                <Grid container sx = {{mb: 1, fontSize: "1.2rem"}} color = {grey[600]}>
                    <Grid xs = {4}>
                        <Box sx = {{ mt: 2}}>
                            <Typography>
                                Select Plate Material
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs = {4}>
                        <Box sx = {{ mt: 2}}>
                            <Typography>
                                Select {getJointinfo.strap_stringer} {" "} Material
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs = {4}>
                        <Box sx = {{ mt: 2}}>
                            <Typography>
                                Select Fastener Material
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container sx = {{mb: 3, fontSize: "1.2rem"}} color = {grey[600]}>
                    <Grid xs = {4}>
                        <Box>
                            <Box>
                                <select
                                    disabled={getJointinfo.elasticity === "User Input"}
                                    name="plate_combobox1"
                                    value={getJointinfo.plate_combobox1}
                                    onChange={setJointInfo}
                                    className="selectclass"
                                    required={true}
                                >
                                    <option value=""> Select Material </option>
                                    {getJointMaterial.length > 0 &&
                                        getJointMaterial[0].map((material) => (
                                            <option key={material} value={getJointMaterial[0].indexOf(material)}>
                                                {material}
                                            </option>
                                        ))}

                                </select>
                            </Box>
                            <Box sx={{mt: 1}} >
                                <select
                                    disabled={getJointinfo.elasticity === "User Input"}
                                    name="plate_combobox2"
                                    value={getJointinfo.plate_combobox2}
                                    onChange={setJointInfo}
                                    className="selectclass"
                                    required={true}
                                >
                                    <option value="">Select Alloy Group</option>
                                    {getJointinfo.plate_combobox1 > -1 ?
                                        plateRenderSwitch1(): null}

                                </select>
                            </Box>
                            <Box  sx={{mt: 1}} >
                                <select
                                    disabled={getJointinfo.elasticity === "User Input"}
                                    name="plate_combobox3"
                                    value={getJointinfo.plate_combobox3}
                                    onChange={setJointInfo}
                                    className="selectclass"
                                    required={true}
                                >
                                    <option value="">Select Temper</option>
                                    {getJointinfo.plate_combobox2 > -1 ?
                                        plateRenderSwitch2(): null}
                                </select>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid xs = {4}>
                        <Box>
                            <Box>
                                <select
                                    disabled={getJointinfo.elasticity === "User Input"}
                                    name="strap_combobox1"
                                    value={getJointinfo.strap_combobox1}
                                    onChange={setJointInfo}
                                    className="selectclass"
                                    required={true}
                                >
                                    <option value="">Select Material</option>
                                    {getJointMaterial.length > 0 &&
                                        getJointMaterial[0].map((material) => (
                                            <option value={getJointMaterial[0].indexOf(material)} key={material}>
                                                {material}
                                            </option>
                                        ))}
                                </select>
                            </Box>
                            <Box sx={{mt: 1}}>
                                <select
                                    disabled={getJointinfo.elasticity === "User Input"}
                                    name="strap_combobox2"
                                    value={getJointinfo.strap_combobox2}
                                    onChange={setJointInfo}
                                    className="selectclass"
                                    required={true}
                                >
                                    <option value="">Select Alloy Group </option>
                                    {getJointinfo.strap_combobox1 > -1 ?
                                        strapRenderSwitch1(): null}
                                </select>
                            </Box>
                            <Box sx={{mt: 1}} >
                                <select
                                    disabled={getJointinfo.elasticity === "User Input"}
                                    name="strap_combobox3"
                                    value={getJointinfo.strap_combobox3}
                                    onChange={setJointInfo}
                                    className="selectclass"
                                    required={true}
                                >
                                    <option value="">Select Temper </option>
                                    {getJointinfo.strap_combobox2 > -1 ?
                                        strapRenderSwitch2(): null}
                                </select>
                            </Box>
                        </Box>
                    </Grid>





                    <Grid xs = {4}>
                        <Box>
                            <Box >
                                <select
                                    disabled = {getJointinfo.elasticity === "User Input" & getJointinfo.flexibility === "Huth"}
                                    name = "fastener_combobox1"
                                    value = {getJointinfo.fastener_combobox1}
                                    onChange = {setJointInfo}
                                    className = {jointInputError.fastener_combobox1 ? "selectclass form-input-fail" : "selectclass"}
                                    required = {true}
                                >
                                    <option value="-1">Select Material</option>
                                    {getJointMaterial.length > 0 &&
                                        getJointMaterial[0].map((material) => (
                                            <option value={getJointMaterial[0].indexOf(material)} key={material}>
                                                {material}
                                            </option>
                                        ))}
                                </select>
                            </Box>
                            <Box sx={{mt: 1}}>
                                <select
                                    disabled={getJointinfo.elasticity === "User Input"}
                                    name="fastener_combobox2"
                                    value={getJointinfo.fastener_combobox2}
                                    onChange={setJointInfo}
                                    className="selectclass"
                                    required={true}
                                >
                                    <option value="-1">Select Alloy Group </option>
                                    {getJointinfo.fastener_combobox1 > -1 ?
                                        fastenerRenderSwitch1(): null}
                                </select>
                            </Box>
                            <Box sx={{mt: 1}} >
                                <select
                                    disabled={getJointinfo.elasticity === "User Input"}
                                    name="fastener_combobox3"
                                    value={getJointinfo.fastener_combobox3}
                                    onChange={setJointInfo}
                                    className="selectclass"
                                    required={true}
                                >
                                    <option value="-1">Select Temper</option>
                                    {getJointinfo.fastener_combobox2 > -1 ?
                                        fastenerRenderSwitch2(): null}
                                </select>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container sx = {{mb: 1,fontSize: "1.2rem"}} color = {grey[600]}>
                    <Grid xs = {4}>
                        <Box sx = {{ mt: 3}}>
                            <Typography>
                                Plate Modulus of Elasticity
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs = {4}>
                        <Box sx = {{ mt: 3}}>
                            <Typography>
                                {getJointinfo.strap_stringer} {" "} Modulus of Elasticity
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs = {4}>
                        <Box sx = {{ mt: 3}}>
                            <Typography>
                                Fastener Modulus of Elasticity
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container sx = {{mb: 3, fontSize: "1.2rem"}} color = {grey[600]}>
                    <Grid xs = {4}>
                        <Box>
                            <input
                                disabled = {getJointinfo.elasticity === "MMPDS"}
                                name="plate_elasticity"
                                type="number"
                                value={getJointinfo.plate_elasticity}
                                onChange={setJointInfo}
                                className={
                                    jointInputError.plate_elasticity ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                }
                                required={true}
                                onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                min={0}
                            />
                        </Box>
                    </Grid>
                    <Grid xs = {4}>
                        <Box >
                            <input
                                disabled={getJointinfo.elasticity === "MMPDS"}
                                name="strap_elasticity"
                                type="number"
                                value={getJointinfo.strap_elasticity}
                                onChange={setJointInfo}
                                className={
                                    jointInputError.strap_elasticity ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                }
                                required={true}
                                onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                min={0}
                            />
                        </Box>
                    </Grid>
                    <Grid xs = {4}>
                        <Box>
                            <input
                                disabled={getJointinfo.elasticity === "MMPDS"}
                                name="fastener_elasticity"
                                type="number"
                                value={getJointinfo.fastener_elasticity}
                                onChange={setJointInfo}
                                className={
                                    jointInputError.fastener_elasticity ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                                }
                                required={true}
                                onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                                min={0}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
export default Material;