import {Box, Fab} from "@mui/material";
import {red} from "@mui/material/colors";
import {MenuRounded} from "@mui/icons-material";
import {useContext} from "react";
import MainContext from "../../context";
import {useAuthUser} from 'react-auth-kit';
const DrawerActionButton = () =>{
    const {setDrawerOpen, setDrawerOpenJoint} = useContext(MainContext)
    const auth = useAuthUser()
    return(
        <Box
            sx={{
                position: "absolute",
                display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                    zIndex:2,
                },
            }}
        >
            <Fab
                aria-label="Sidebar"
                size="small"
                onClick={() => (auth().Soft === "Dent")? setDrawerOpen(true):setDrawerOpenJoint(true)}
                sx={{ backgroundColor: red[900], mt:2,  }}
            >
                <MenuRounded />
            </Fab>
        </Box>
    );
}
export default DrawerActionButton;