import {Box, Button, Divider, Link, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {grey, red} from "@mui/material/colors";
import {CopyrightRounded} from "@mui/icons-material";
import { useCollapse } from 'react-collapsed';
import {useContext} from "react";
import MainContext from "../../context";
import {useAuthUser} from 'react-auth-kit';

function Copyright() {
    return (
        <Typography variant="caption" color= {grey[600]} >
            {"Copyright" } <CopyrightRounded sx = {{verticalAlign: "middle", height: 16}} />
            <Link className="fullAddress"  color="inherit" href="https://sca-co.com/">
                SCA - Serviços de Certificação Aeronáutica. All right reserved.
            </Link>

            <Link className="lessAddress" color="inherit" href="https://sca-co.com/"  >
                SCA Co. All right reserved.
            </Link>

            {' '}

            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Footer = () => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    const {getJointinfo, setJointInfo, handleDentInfo, getDentinfo} = useContext(MainContext);
    const auth = useAuthUser()
    let unitValue1 = "English Units: inches, ksi, lbf"
    let unitValue2 = "S.I. Units: mm, MPa, N"

    if (auth().Soft === "Dent"){
        unitValue1 = "English Units: inches, psi, lbf"
        unitValue2 = "S.I. Units: mm, Pa, N"
    }




    return(
        <Box
            component="footer"
            sx={{
                mt: 'auto',
            }}
        >
            <Divider variant="middle"  sx = {{borderColor:grey[400]}} />


            <Container maxWidth="xll" sx = {{height: "80px", position: "relative", }}>
                <Box sx = {{position: "absolute", top: "50%",left: "50%", transform: "translate(-50%, -50%)", }}>
                    <Box style={{justifyContent: "center", display: "flex" }}>
                        <img
                            src={require("../../assets/logo0.png")}
                            alt="SCA"
                            className="footerImg"
                        />
                    </Box>
                    <Copyright />
                </Box>

                <Box sx = {{position: "absolute",right: 15,  top: "50%", transform: "translate(0, -50%)", }} >

                    <Box  className="collapsible">
                        <Box {...getCollapseProps()}>
                            <Box className="content" >
                                <Box  >
                                    <Typography> <input checked={(auth().Soft === "Dent") ? getDentinfo.unit === '1' :getJointinfo.unit === '1'} onChange={(auth().Soft === "Dent") ? handleDentInfo:setJointInfo} className="radiobtn" type="radio" value="1" name="unit" /> {unitValue1} </Typography>
                                    <Typography> <input checked={(auth().Soft === "Dent") ? getDentinfo.unit === '2' :getJointinfo.unit === '2'} onChange={(auth().Soft === "Dent") ? handleDentInfo:setJointInfo} className="radiobtn" type="radio" value="2" name="unit" /> {unitValue2} </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx = {{ justifyContent: "center",backgroundColor: red[900], }} className="header" {...getToggleProps()}>
                            <Typography sx={{color:"#ffffff"}}> Unit </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}
export default Footer;