import {CopyrightRounded, NoteAddOutlined} from "@mui/icons-material";
import {grey, red} from "@mui/material/colors";
import {Button, Box, Typography} from "@mui/material";
import {
    SettingsOutlined,PowerSettingsNewTwoTone
} from "@mui/icons-material";
import {useContext} from "react";
import MainContext from "../../context";
import {useSignOut} from "react-auth-kit";
import {useNavigate} from "react-router-dom";

const SidebarFooter = () =>{

    const signOut = useSignOut();
    const navigate = useNavigate();
    const logOut = () =>{
        signOut();
        navigate("/");
    }
    return(
        <>
            <Box sx = {{alignContent: "center",  textAlign:"center", justifyContent:"center", mt: 'auto', mb: 2, }} >
                <Button
                    className="home btn vertical"
                    component="label"
                    onClick= {logOut}
                >
                    <PowerSettingsNewTwoTone  sx={{ fontSize: "2.5rem", color: grey[500],  }} />
                    <Typography style={{color: grey[300], paddingTop: 7, fontSize: "0.7rem", lineHeight: 1.2, fontWeight: 700, }}  >
                        SIGN OUT
                    </Typography>
                </Button>
            </Box>
        </>
    );
}
export default SidebarFooter;
