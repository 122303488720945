import {Box, Typography} from "@mui/material";

const JointManual = () => {
    return(
        <Box sx = {{mt: 10, ml: 5, mr: 5}}>
            <Box >
                <Typography sx = {{fontFamily: "impact, sans-serif", fontSize: "2.0rem", pl:1,  color: "#fff"}}>
                    JOINT
                </Typography>
                <Typography align="justify" sx = {{fontFamily: "sans-serif",  color: "#fff", fontWeight: 700 , fontSize: "0.9rem", pl:1, borderBottom: "1px solid "}}>
                    JointLoad Transfer & Fatigue Life Calculation
                </Typography>
            </Box>
            <Box sx = {{p: 3, fontStyle: 'italic',}}>
                <Typography align="justify" sx = {{ color: "#fff"}}>
                    The load distribution on fasteners in symmetrical joints is not equal. Therefore, the calculation of these forces is one of the primary concerns of engineers.
                </Typography>
                <Typography align="justify" sx = {{pt: 1,color: "#fff"}}>
                    This software calculates the bearing and bypass loads and severity factors for multiple fasteners in different configurations to overcome this challenge.
                </Typography>
            </Box>
        </Box>
    );
}
export default JointManual;