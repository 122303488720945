import {Box,Avatar,Typography } from "@mui/material"
import { AccountCircleOutlined} from "@mui/icons-material";
import {useContext} from "react";
import MainContext from "../../context";
import {grey, red} from "@mui/material/colors";
import {useAuthUser} from 'react-auth-kit';

const SidebarHeader = () => {
    const auth = useAuthUser()
    return (
        <>
            <AccountCircleOutlined
                    sx={{ fontSize: "2.5rem", margin: "0 auto", color: grey[500], mt: 2, }}
            />
            <Box
                sx={{ margin: "5 0" }}>
                <Typography variant="caption" style={{color: grey[300], paddingTop: 7, fontSize: "0.7rem", lineHeight: 1.2, fontWeight: 500 }}>
                    {auth().user}
                </Typography>
            </Box>
        </>
    );
}
export default SidebarHeader;