import {useContext} from "react";
import MainContext from "../../context";
import Chart from "chart.js/auto";
import {Bar } from 'react-chartjs-2';
import Grid from "@mui/material/Unstable_Grid2";
import {Button, Box, Divider, Typography} from "@mui/material";
import { CategoryScale } from "chart.js";
import {grey} from "@mui/material/colors";
import {Header} from "./index";
import {Footer} from "../components";
import { saveAs } from 'file-saver';
import {useAuthUser} from "react-auth-kit";
import axios, {AxiosError} from "axios";
Chart.register(CategoryScale);

const ResultPage = () => {
    const {BacktoMain, state, getPlottype,handlePlotType, plotData , getJointinfo} = useContext(MainContext);
    // const savecanvas = () => {
    //     const canvasSave = document.getElementById('barchart');
    //     canvasSave.toBlob(function (blob) {
    //         saveAs(blob, "testing.png")
    //     })
    // }
    const auth = useAuthUser()
    // const DownloadFile = () => {
    //     let value1 = auth().token
    //     async function fetchData3() {
    //         try {
    //             const response = await axios.get(auth().website , value1
    //             );
    //             console.log(response)
    //         } catch (err) {
    //             if (err && err instanceof AxiosError)
    //                 console.log("Error: ");
    //             else if (err && err instanceof Error)
    //                 console.log("Error: ");
    //
    //             console.log("Error: ");
    //         }
    //     }
    //     fetchData3();
    // }


    return(
        <Box
            sx={{
                height: "100vh",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header />
            <Box sx = {{height: "70%",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: "whitesmoke", border: "1px solid whitesmoke" ,borderRadius: 5,mt: 2.5, ml: 2.5,mr: 2.5, p: 2}} >
                <Box sx = {{ fontSize: "1.2rem",  mt: 2, ml: 2}} color = {grey[600]} >
                    <Typography  variant="subtitle1"   > GET OUTPUT </Typography>
                    <Divider variant="middle" sx = {{borderColor:grey[400]}}   />
                    <Box sx = {{mt: 2}}>
                        <Box  sx = {{textAlign : "center"}} >
                            <Typography sx = {{display: "inline"}}> <input checked={getPlottype.plot_type === "Bearing Load" } onChange={handlePlotType} className="radiobtn" type="radio" value="Bearing Load" name="plot_type" />  Bearing Load </Typography>
                            <Typography sx = {{display: "inline", ml:2}}> <input checked={getPlottype.plot_type === "Bearing Load/F x 100" }  onChange={handlePlotType} className="radiobtn" type="radio" value="Bearing Load/F x 100" name="plot_type" /> Bearing Load/F x 100 </Typography>
                            <Typography sx = {{display: "inline", ml: 2}} > <input checked={getPlottype.plot_type === "Local Peak Stress" }  onChange={handlePlotType} className="radiobtn" type="radio" value="Local Peak Stress" name="plot_type" />Local Peak Stress </Typography>
                            <Typography sx = {{display: "inline", ml: 2}}> <input checked={getPlottype.plot_type === "Severity Factor" }  onChange={handlePlotType} className="radiobtn" type="radio" value="Severity Factor" name="plot_type" />  Severity Factor </Typography>
                            <Typography  sx = {{ml: 2, display: getJointinfo.fatigue === "Yes" ? "inline" : "none"}}> <input checked={getPlottype.plot_type === "Fatigue Life" }  style={{display: getJointinfo.fatigue === "Yes" ? "inline-block" : "none"}} onChange={handlePlotType} className="radiobtn" type="radio" value="Fatigue Life" name="plot_type" />  Fatigue Life </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Grid container >
                            <Grid xs = {1}> </Grid>
                            <Grid xs = {7} >
                                <Box  sx ={{ mt: 5, display: getPlottype.show_plot ? "block" : "none" }}    >
                                    <Bar id="barchart"
                                         datasetIdKey='id'
                                         data={state}
                                         width={100}
                                         height={50}
                                         options={{
                                             responsive: true,
                                             maintainAspectRatio: true,
                                             scales: {
                                                 x: {
                                                     display: true,
                                                     title: {
                                                         display: true,
                                                         text: 'Fastener'
                                                     },
                                                 },
                                             }

                                         }}
                                    />
                                </Box>
                                <Box  style={{mt: 5, display: getPlottype.show_plot ? "none" : "block"}}  >
                                    <Box sx = {{mt: 5, mr: 1, textAlign: "center"}}>
                                        <img
                                            src = {require(`../../assets/fatigue/${plotData["Fatigue Image"]}.png`)}
                                            alt = "JointLoad"
                                            style = {{width: "70%"}}
                                            id  = "JointImg"
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs = {1}> </Grid>
                            <Grid xs = {3}>
                                <Box sx = {{mt: 20, }}>
                                    {/*<Box >*/}
                                    {/*    <Button variant="contained" value="RunJoint" onClick={savecanvas}*/}
                                    {/*            sx = {{my: 0.5,*/}
                                    {/*                mx:1, width: "80%",  fontWeight: 700,*/}
                                    {/*            }}> SAVE OUTPUT </Button>*/}
                                    {/*</Box>*/}

                                    <Box>
                                            <Button variant="contained" value="RunJoint" href= "https://app.sca-co.com/sevin/protected/1005/DTE61.docx"
                                                     sx = {{my: 0.5,
                                                         mx:1, width:"80%",  fontWeight: 700,
                                                     }}>  SAVE OUTPUT </Button>
                                    </Box>


                                    <Box  >
                                        <Button  variant="contained" value="RunJoint" onClick={BacktoMain}
                                                 sx = {{my: 0.5,
                                                     mx:1, width:"80%",  fontWeight: 700,
                                                 }}>BACK TO PROJECT </Button>
                                    </Box>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

               <Box sx={{ml: 5}}>
                   <Grid container >
                       {getPlottype.plot_description.map((val, key) => {
                           return (
                               <Grid xs = {4}  key={key}>
                                   <Box sx = {{margin: 3}}>
                                       <Typography sx = {{borderBottom: "1px solid #bdbdbd",fontWeight: 700}}> {val} </Typography>
                                       <Typography sx = {{mt: 1}}>
                                           {plotData[val].split("\n").map((i,key) => {
                                               return <Box key={key}>{i}</Box>;
                                           })}
                                       </Typography>
                                   </Box>
                               </Grid>
                           )
                       })}
                   </Grid>
               </Box>
            </Box>
            <Footer />
        </Box>
    );
}
export default ResultPage;

