import {useContext} from "react";
import MainContext from "../../context";
import {Box, Button, Divider, Typography} from "@mui/material";
import Header from "./Header";
import {grey} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import {Footer} from "./index";
const ResultPage = () => {
    const {getOutput, BacktoMainDent, getDentinfo, Description } = useContext(MainContext);
    return (
        <Box
            sx={{
                height: "100vh",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header />
            <Box sx = {{height: "70%",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: "whitesmoke", border: "1px solid whitesmoke" ,borderRadius: 5,mt: 2.5, ml: 2.5,mr: 2.5, p: 2}} >
                <Box sx = {{ fontSize: "1.2rem",  mt: 2, ml: 2}} color = {grey[600]} >
                    <Typography  variant="subtitle1"  > GET OUTPUT </Typography>
                    <Divider variant="middle" sx = {{borderColor:grey[400]}} />
                    <Grid container >
                        <Grid xs = {1}> </Grid>
                        <Grid xs = {5} >
                            <Box sx = {{mt: 5, mr: 1, textAlign: "center"}}>
                                <img
                                    src = {require(`../../assets/DentImg/${getDentinfo.dentImg}.png`)}
                                    alt = "JointLoad"
                                    id  = "DentImg"
                                    style={{width: "100%"}}
                                />
                            </Box>
                        </Grid>
                        <Grid xs = {5}>
                            <Box sx = {{mt: 8}}>
                                {Description.map((val, key) => {
                                  return(
                                    <Box key={key} sx={{margin: 5}}>
                                        <Typography sx={{
                                            borderBottom: "1px solid #bdbdbd",
                                            fontWeight: 700
                                        }}> {val} </Typography>
                                        <Typography sx={{mt: 1}}>
                                            {getOutput[val]}
                                        </Typography>
                                    </Box>
                                    );
                                })}
                            </Box>

                        </Grid>
                        <Grid xs = {1}> </Grid>
                    </Grid>

                </Box>

                <Box  sx = {{textAlign: "left", mt: 'auto',  }}>
                    <Button  variant="contained" value="RunJoint" onClick={BacktoMainDent}
                             sx = {{my: 0.5,mx:3, width: 250, fontWeight:700}}>
                        BACK TO PROJECT
                    </Button>
                </Box>
            </Box>
            <Footer />
        </Box>

    );
}

export default ResultPage;