import {Box, Divider, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import {useContext} from "react";
import MainContext from "../../context";

const ACInfo = () => {
    const {getDentinfo, handleDentInfo, dentInputError} = useContext(MainContext);


    return(
        <Box sx = {{mt:5, mb: 5}}>
            <Typography variant="subtitle1" > A/C INFORMATION </Typography>
            <Divider variant="middle" sx = {{borderColor:grey[400]}} />

            <Grid container>
                <Grid xs={4}
                >
                    <Box sx = {{ml : 5, mt: 2}}>
                        <Typography  sx = {{typography: {xl: 'body1', lg: "subtitle2", md: "subtitle2" , sm: "subtitle2", xs: "caption" }, }}>
                            Panel Thickness
                        </Typography>
                    </Box>
                </Grid>


                <Grid xs={4}
                      direction= "column"
                >
                    <Box sx = {{ml : 5, mt: 2}}>
                        <Typography  sx = {{typography: {xl: 'body1', lg: "subtitle2", md: "subtitle2" , sm: "subtitle2", xs: "caption" },}}>
                            Fuselage Radius
                        </Typography>
                    </Box>
                </Grid>
                <Grid xs={4}
                      direction= "column"
                >
                    <Box sx = {{ml : 5, mt: 2}}>
                        <Typography  sx = {{typography: {xl: 'body1', lg: "subtitle2", md: "subtitle2" , sm: "subtitle2", xs: "caption" },}} >
                            Normal Operating Pressure
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container>
                <Grid xs={4}
                >
                    <Box sx = {{ml : 5}}>
                        <input
                            name="thickness"
                            type="number"
                            value={getDentinfo.thickness}
                            onChange={handleDentInfo}
                            className={
                                dentInputError.thickness ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                            }
                            required={true}
                            min="0"
                            onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                            min={0}
                        />
                    </Box>
                </Grid>

                <Grid xs={4}
                      direction= "column"
                >
                    <Box sx = {{ml : 5}}>
                        <input
                            name="radius"
                            type="number"
                            value={getDentinfo.radius}
                            onChange={handleDentInfo}
                            className={
                                dentInputError.radius ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                            }
                            required={true}
                            onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                            min={0}
                        />
                    </Box>
                </Grid>
                <Grid xs={4}
                      direction= "column"
                >
                    <Box sx = {{ml : 5}}>
                        <input
                            name="delta_pressure"
                            type="number"
                            value={getDentinfo.delta_pressure}
                            onChange={handleDentInfo}
                            className={
                                dentInputError.delta_pressure ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                            }
                            required={true}
                            onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                            min={0}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>

    );
}
export default ACInfo;