import {Box, Button, Divider, Typography} from "@mui/material";
import {grey, red} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import {useContext} from "react";
import MainContext from "../../context";
import {ErrorOutlineRounded} from "@mui/icons-material";

const DentInfo = () => {
    const {getDentinfo, handleDentInfo, dentInputError, fullGuideLine} = useContext(MainContext);
    let val1  = 0.3
    let val2 = 0.15
   if (getDentinfo.unit === "2") {
       val2  = 3.81
       val1 = 7.62
   }

    return(
        <Box sx = {{ mt: 2, mb: 5}}>
            <Typography variant="subtitle1" > DENT INFORMATION </Typography>
            <Divider variant="middle" sx = {{borderColor:grey[400]}} />
            <Grid container>
                <Grid xs={4}
                >
                    <Box sx = {{ml : 5, mt: 2}}>
                        <Typography  sx = {{typography: {xl: 'body1', lg: "subtitle2", md: "subtitle2" , sm: "subtitle2", xs: "caption" }}}>
                           Dent Width
                        </Typography>
                        <input
                            name="width"
                            type="number"
                            value={getDentinfo.width}
                            min="0"
                            onChange={handleDentInfo}
                            className={
                                dentInputError.width ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                            }
                            required={true}
                            onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                            min={0}
                        />
                        <Typography  sx = {{typography: {xl: 'body1', lg: "subtitle2", md: "subtitle2" , sm: "subtitle2", xs: "caption" }, mt: 3}}>
                             Dent Depth
                        </Typography>

                        <input
                            name="depth"
                            type="number"
                            value={getDentinfo.depth}
                            onChange={handleDentInfo}
                            className={
                                dentInputError.depth ? "inputclass form-input-fail noscroll" : "inputclass noscroll"
                            }
                            required={true}
                            onKeyDown={(e) => ((e.key === '-') || (e.key === '+')) ? e.preventDefault(): null}
                            min={0}
                        />
                        <span id = "hoverBTN" > <Button  sx = {{color: red[900], }}  > <ErrorOutlineRounded sx = {{fontSize: "0.90rem"}}/></Button> <Typography className="info" sx = {{fontSize: "0.65rem", fontStyle: 'italic', pl: 2,pr:3, color:red[600]}}>
                            "Lower than {" "} {val1} {" "}inches in the Al 2024 and {" "} {val2} {" "} inches in the Al 7075"
                        </Typography></span>
                    </Box>
                </Grid>
                <Grid xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={8}
                      direction= "column"
                >
                    <Box sx = {{m: 3,fontSize: "1.2rem"}} color = {grey[600] }>
                        <Box >
                            <Typography sx={{float:"left", fontSize: "0.9rem"}}> YES </Typography>
                            <Typography sx ={{ ml: 5,fontSize: "0.9rem" }}> NO </Typography>
                        </Box>

                        <Box  sx = {{mt: 2, display: "inline-block"}} >
                            <input checked={getDentinfo.criticality === 'Yes'} onChange={handleDentInfo} className={dentInputError.criticality ? "radiobtn form-input-fail" : "radiobtn"}  type="radio" value="Yes" name="criticality" />
                            <input checked={getDentinfo.criticality === 'No'} onChange={handleDentInfo} style = {{marginLeft: 20 , }} className={dentInputError.criticality ? "radiobtn form-input-fail" : "radiobtn"}  type="radio" value="No" name="criticality" />
                            <Typography sx = {{typography: {xl: 'body1', lg: "subtitle2", md: "subtitle2" , sm: "subtitle2", xs: "caption" },ml: 5, display: "inline-block" }}> Is the dent in a critical region? </Typography>
                        </Box>

                        <span id = "hoverCriticalBTN" > <Button  onClick={fullGuideLine}  sx = {{color: red[900], }}  > <ErrorOutlineRounded sx = {{fontSize: "0.90rem"}}/></Button>
                                <Typography className="criticalInfo" sx = {{fontSize: "0.65rem", fontStyle: 'italic', pl: 12,pr:3, color:red[600]}}>"Critical region is defined on the graph to the right, Click on the button to see it clearly." </Typography>
                        </span>

                        <Box  sx = {{mt: 1.5,}}>
                            <input checked={getDentinfo.reworked === 'Yes'} onChange={handleDentInfo} className={dentInputError.reworked ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Yes" name="reworked" />
                            <input checked={getDentinfo.reworked === 'No'} onChange={handleDentInfo} style = {{marginLeft: 20}} className={dentInputError.reworked ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="No" name="reworked" />
                            <Typography sx = {{typography: {xl: 'body1', lg: "subtitle2", md: "subtitle2" , sm: "subtitle2", xs: "caption" },ml: 5, display: "inline-block" }}> Has the dented region been reworked? </Typography>
                        </Box>

                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
export default DentInfo;
