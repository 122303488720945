import React,{useState} from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import SidebarTabs2 from "../components/sidebar/SidebarTabs2";
import {Container, Box, Button, Typography} from "@mui/material";
import Page from "../components/sidebar/TabPanel";
import MainContext from "../context";
import {grey} from "@mui/material/colors";
import {DentManual, JointManual, LoginManual} from "../pages/manuals";
import { ThemeProvider } from "@mui/material/styles";
import {theme} from "../layouts/theme";
import {useSignIn} from "react-auth-kit";
import {useNavigate} from "react-router-dom";
import axios, {AxiosError} from "axios";

const Login = () => {
    const [error, setError] = useState("");
    const signIn = useSignIn();
    const navigate = useNavigate();
    const [getSoft, setSoft] = useState("")
    const [values,setValues] = useState({
        username: "",
        password:""
    });
    const setSoftInfo = (event) => {
        setSoft(event.target.value)
    }
    const handleUser = (event) => {
        setValues({...values, [event.target.name]: event.target.value,});
    }

    const ValidateUser = async (e) => {
        e.preventDefault()
        setError("");
        try {
            const response = await axios.post("https://app.sca-co.com/sevin/token",
                values
            );
            signIn({
                token: response.data.token,
                expiresIn: 3600,
                tokenType: "Bearer",
                authState: { user: values.username, Soft: getSoft, token: response.data.token, website: "https://app.sca-co.com/sevin"},
            });
            if (getSoft  == "Dent")
            {
                navigate("/Dent");
            }
            if (getSoft  == "Joint")
            {
                navigate("/Joint");
            }

        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);

            console.log("Error: ", err);
        }
    };

    const [pageNumber, setPageNumber] = useState(0);
    const handlePageNumber = (event, newPage) => {
        setPageNumber(newPage);
    };
    return(
        <MainContext.Provider value={{ pageNumber, handlePageNumber, }} >
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        height: "100vh",
                        overflowY: "scroll",
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: grey[300],
                    }}
                >
                    <Container>
                        <Box sx = {{ margin: "10% auto", backgroundColor: "#ffffff", boxShadow: "0 0 10px 5px #0000002e",}}>


                            <Grid container>

                                <Grid xs = {1}>
                                    <Box sx = {{textAlign: "center", justifyContent: 'center',
                                        height: '100%', display:'flex',}}
                                         className="legend-label"
                                    >
                                        <SidebarTabs2 />
                                    </Box>
                                </Grid>
                                <Grid xs = {6}>
                                    <Box sx = {{ marginTop: "-10%", height: "120%", justifyContent: 'center',
                                        display:'flex', }}
                                         className = "bg-image-with-overlay"
                                    >
                                        <Page pageNumber={pageNumber} index={0}>
                                            <LoginManual />
                                        </Page>
                                        <Page pageNumber={pageNumber} index={1}>
                                            <DentManual />
                                        </Page>
                                        <Page pageNumber={pageNumber} index={2}>
                                            <JointManual />
                                        </Page>
                                    </Box>
                                </Grid>
                                <Grid xs = {5}>
                                    <Box >
                                        <Box className="form-wrap">
                                            <Box sx={ {textAlign: "center", mt: "5%"}}>
                                                <img
                                                    src={require("../assets/logo.png")}
                                                    alt="SCA"
                                                    style={{width: "25%"}}
                                                />
                                            </Box>
                                            <form onSubmit={ValidateUser}>
                                                <div className="row">
                                                    <Typography htmlFor="userName" sx = {{fontSize: "0.70rem"}}>User Name</Typography>
                                                    <input type="text" name="username" autoComplete="off" style={{width: "95%"}}
                                                           placeholder="User Name" value={values.username} onChange={handleUser} />
                                                </div>
                                                <div className="row">
                                                    <Typography htmlFor="password" sx = {{fontSize: "0.70rem"}}>Password</Typography>
                                                    <input type="password" name="password" style={{width: "95%"}} placeholder= "***"  value={values.password} onChange={handleUser} />

                                                </div>
                                                <Box  sx = {{mt:1}}>
                                                    <Typography sx = {{fontSize: "0.8rem"}}> <input onChange={setSoftInfo} className="radiobtn radiopadding" type="radio" value="Dent" name="getSoft" /> DENT </Typography>
                                                    <Typography sx = {{fontSize: "0.8rem"}}> <input  onChange={setSoftInfo} className="radiobtn radiopadding" type="radio" value="Joint" name="getSoft" /> JOINT </Typography>
                                                </Box>

                                                <Box  sx = {{textAlign: "center", mt: 5,}}>
                                                    <Button variant="contained" className="my-button"  type="submit"
                                                            sx = {{
                                                                width: "50%", fontWeight: 700,

                                                            }}> Login  </Button>
                                                </Box>
                                            </form>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </ThemeProvider>
        </MainContext.Provider>
    );
}
export default Login;