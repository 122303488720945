import Grid from "@mui/material/Unstable_Grid2";
import {grey} from "@mui/material/colors";

const PagesContainer = ({ children }) => {
    return (
        <Grid
            xs={12}
            sm={12}
            md={11}
            lg={11}
            xl={11}
            sx={{ height: "100vh", overflow: "hidden", backgroundColor: grey[300], }}
        >
            {children}
        </Grid>
    );
};

export default PagesContainer;
