import {Alert, Box, Button, Typography, Collapse, AlertTitle } from "@mui/material";
import {grey, red} from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import {useContext, useState} from "react";
import MainContext from "../../context";
import {DentInfo, ACInfo, MaterialInfo, Guideline, Header, Footer} from "./index";
//color="text.secondary"

const MainPage = () => {
    const {runDent, getOutput, alertOpen, setAlertOpen } = useContext(MainContext);

    return (
        <>
            <div
                style={{
                    display: alertOpen ? "block" : "none",
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                    <Alert severity="warning" >
                        <AlertTitle>Warning</AlertTitle>
                        Incorrect input value — <strong>check it out!</strong>
                        <Box sx={{mt: 1}} id = "alert">
                            <Button color="inherit" size="small" onClick={() => {setAlertOpen(false);}}>
                                OK
                            </Button>
                        </Box>
                    </Alert>
            </div>
            <Box
                sx={{
                    height: "100vh",
                    //overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Header />
                <Box sx = {{height: "95%",
                    overflowY: "scroll",
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 2.5, mr: 2.5, borderRadius: 5, border: "1px solid #e0e0e0", ml: 2.5, mb:2
                }}>
                    <Grid container>
                        <Grid
                            xs={8}
                        >
                            <Box sx = {{height: "95%", backgroundColor: "whitesmoke", border: "1px solid whitesmoke" ,borderRadius: 5,mb: 2.5,mr: 2.5, p: 2}}>
                                <DentInfo />
                                <ACInfo />
                                <MaterialInfo />
                            </Box>
                        </Grid>
                        <Grid
                            xs={4}
                           >
                            <Guideline />
                            <Box  sx = {{textAlign: "center", mt: 1 }}>
                                <Button variant="contained"  value="runDent" onClick={runDent}
                                        sx = {{width: "70%",
                                            my: 0.5,
                                            mx:1, fontWeight: 700,
                                        }}> RUN PROGRAM
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Footer />
            </Box>
        </>

    );
};

export default MainPage;