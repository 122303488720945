import { useContext } from "react";
import {HomeRounded,TextSnippetRounded,CastRounded} from "@mui/icons-material";
import {Tabs, Tab} from "@mui/material";
import MainContext from "../../context";

const SidebarTabs2 = () => {
    const { pageNumber, handlePageNumber } =  useContext(MainContext);

    const tabProps = (index) => {
        return {
            id: `sidebar-tab-${index}`,
            "aria-controls": `tabpanel-${index}`,
        };
    };

    const data = [
        { label: "LOGIN", icon: <HomeRounded />, ...tabProps(0) },
        { label: "DENT", icon: <CastRounded />, ...tabProps(1) },
        { label: "JOINT", icon: <TextSnippetRounded />, ...tabProps(2) },
    ];

    return (
        <Tabs
            orientation="vertical"
            variant="scrollable"
            scrollButton="auto"
            allowScrollButtonsMobile
            value={pageNumber}
            onChange={handlePageNumber}
            /*TabIndicatorProps={{
                style: {
                    backgroundColor: "#D97D54"
                }
            }}*/
            TabIndicatorProps={{
                sx: { width: "5% !important", } //
            }}

            textColor="inherit"
           // indicatorColor="inherit"
        >
            {data.map((tab, index) => (
                <Tab
                    key={index}
                    label={tab.label}
                    icon={tab.icon}
                    //iconPosition="start"
                    sx={{
                        //borderRadius: 2,
                        my: 0.5,
                        mx: 2,
                        fontSize: "0.75rem",
                        fontWeight: 700,
                        lineHeight: 1.2,
                        /*"&.MuiTab-root": {
                            minHeight: 70,
                        },*/
                    }}

                    //onClick={() => setDrawerOpen(false)}
                    {...tab}
                />
            ))}
        </Tabs>
    );
};

export default SidebarTabs2;
