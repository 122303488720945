import {useContext} from "react";
import MainContext from "../../context";
import Grid from "@mui/material/Unstable_Grid2";
import {Box, Divider, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
const General = () => {
    const {getJointinfo, setJointInfo, jointInputError} = useContext(MainContext);
    return(
        <Box sx = {{mt: 2}}>
            <Typography  variant="subtitle1"  > GENERAL INFORMATION </Typography>
            <Divider variant="middle"  sx = {{borderColor:grey[400]}} />
            <Box sx = {{ml : 5, mt: 2}}>
                <Grid container>
                    <Grid xs= {4} sx = {{fontSize: "1.2rem"}} color = {grey[600] }>
                        <Box>
                            <Typography color = {grey[600]} > Fastener Flexibility Calculation Theory: </Typography>
                        </Box>

                    </Grid>
                    <Grid xs = {4} sx = {{fontSize: "1.2rem"}} color = {grey[600] }>
                        <Box >
                            <Typography> Modulus of Elasticity Data Sources? </Typography>
                        </Box>
                    </Grid>
                    <Grid xs = {4} sx = {{fontSize: "1.2rem"}} color = {grey[600] }>
                        <Box >
                            <Typography > Fatigue Life Calculation? </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid xs= {4} sx = {{fontSize: "1.2rem"}} color = {grey[600] }>
                        <Box>
                            <Box  sx = {{mt: 1.5,ml: 1.5}}>
                                <Typography > <input  checked={getJointinfo.flexibility === 'Huth'} onChange={setJointInfo}  className={jointInputError.flexibility ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Huth" name="flexibility" />  Huth </Typography>
                                <Typography > <input disabled={getJointinfo.configuration === "2"} checked={getJointinfo.flexibility === 'Swift'} onChange={setJointInfo}   className={jointInputError.flexibility ? "radiobtn form-input-fail" : "radiobtn"} type="radio" value="Swift" name="flexibility" /> Swift </Typography>
                            </Box>
                        </Box>

                    </Grid>
                    <Grid xs = {4} sx = {{fontSize: "1.2rem"}} color = {grey[600] }>
                        <Box >
                            <Box  sx = {{mt: 1.5,ml: 1.5}}>
                                <Typography> <input  onChange={setJointInfo}  type="radio" value="MMPDS" name="elasticity"  checked={getJointinfo.elasticity === "MMPDS"} className={jointInputError.elasticity ? "radiobtn form-input-fail" : "radiobtn"} /> MMPDS </Typography>
                                <Typography > <input  onChange={setJointInfo} type="radio" value="User Input" name="elasticity"  checked={getJointinfo.elasticity === "User Input"}  className={jointInputError.elasticity ? "radiobtn form-input-fail" : "radiobtn"}
                                                     disabled={getJointinfo.fatigue === "Yes"} /> User Input </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs = {4} sx = {{fontSize: "1.2rem"}} color = {grey[600] }>
                        <Box >
                            <Box  sx = {{mt: 1.5,ml: 1.5}} >
                                <Typography> <input checked={getJointinfo.fatigue === "Yes" } onChange={setJointInfo} className="radiobtn" type="radio" value="Yes" name="fatigue" /> Yes </Typography>
                                <Typography> <input checked={getJointinfo.fatigue === "No"} onChange={setJointInfo} className="radiobtn" type="radio" value="No" name="fatigue" /> No  </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
export default General;