import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import {Dent,Joint,Login} from "./containers/index";
import { RequireAuth } from "react-auth-kit";

function App() {
    return (
            <Routes>
                <Route
                    path="/Dent"
                    element={
                        <RequireAuth loginPath="/">
                            <Dent />
                        </RequireAuth>
                    }
                >
                </Route>
                <Route
                    path="/Joint"
                    element={
                        <RequireAuth loginPath="/">
                            <Joint />
                        </RequireAuth>
                    }
                >
                </Route>
                <Route path="/" element={<Login />}></Route>
            </Routes>
    );
}

export default App;