import {grey} from "@mui/material/colors"
import {Box, Button, Divider, Typography} from "@mui/material";
import {
    SystemUpdateAltRounded,
    NoteAddOutlined,
    DriveFolderUploadOutlined,
} from "@mui/icons-material";
import {useContext} from "react";
import MainContext from "../../context";
import {SidebarHeader, SidebarFooter} from "./index";
import {useAuthUser} from 'react-auth-kit';

const SidebarTabs = () => {
    const {NewJointProject, SaveJointProject, LoadJointProject, NewProject, SaveProject, LoadProject} = useContext(MainContext);
    const auth = useAuthUser()
    return(
        <Box
            sx={{
                height: "100vh",
                overflowY: "scroll",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <SidebarHeader   />
            <Divider variant="middle" color={grey[800]} sx={{ mt: 2 }} />

            <Box
                sx = {{mt: "5rem",}}
            >
                <Box
                    sx = {{
                        my: 1,
                        mx:1,
                        "&.MuiTab-root":{
                            minHeight : 50,
                        },
                    }}
                >
                    <Button
                        className="home btn vertical"
                        component="label"
                        onClick= {(auth().Soft === "Dent") ? NewProject : NewJointProject}
                    >
                        <NoteAddOutlined sx = {{fontSize: "2.5rem", margin: "0 auto", color: grey[500]}} />

                        <Typography style={{color: grey[300], paddingTop: 7, fontSize: "0.7rem", lineHeight: 1.2, fontWeight: 700 }}  >
                        NEW PROJECT
                        </Typography>


                    </Button>
                </Box>


                <Box
                    sx = {{
                        my: 3,
                        mx:1,
                        "&.MuiTab-root":{
                            minHeight : 50,
                        },
                    }}
                >
                    <Button
                        className="home btn vertical"
                        component="label"
                        onClick= {(auth().Soft === "Dent") ? SaveProject : SaveJointProject}
                    >
                        <SystemUpdateAltRounded  sx = {{fontSize: "2.5rem", margin: "0 auto", color: grey[500]}} />
                        <Typography style={{color: grey[300], paddingTop: 7, fontSize: "0.7rem", lineHeight: 1.2, fontWeight: 700 }}  >
                            SAVE PROJECT
                        </Typography>
                    </Button>
                </Box>


                <Box
                    sx = {{
                        my: 3,
                        mx:1,
                        "&.MuiTab-root":{
                            minHeight : 50,
                        },
                    }}
                >
                    <Button
                        className="home btn vertical"
                        component="label"
                        onChange= {(auth().Soft === "Dent") ? LoadProject : LoadJointProject}
                    >
                        <DriveFolderUploadOutlined  sx = {{fontSize: "2.5rem", margin: "0 auto", color: grey[500]}} />
                        <input
                            type="file"
                            hidden
                        />
                        <Typography style={{color: grey[300], paddingTop: 7, fontSize: "0.7rem", lineHeight: 1.2, fontWeight: 700 }}  >
                            LOAD PROJECT
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <SidebarFooter />
        </Box>

    );
}
export default SidebarTabs;
