import {createContext} from "react";
export default createContext ({
    result: false,
    imgSrc: "Single",
    state: "",
    drawerOpen : false,
    setDrawerOpen: () => {},
    drawerOpenJoint: false,
    setDrawerOpenJoint: () => {},
    getDentinfo: "",
    handleDentInfo: () => {},
    runDent: () => {},
    getOutput:"",
    NewProject: () => {},
    SaveProject:() => {},
    LoadProject: () => {},
    getJointinfo:"",
    setJointInfo:() => {},
    getJointMaterial:"",
    runJoint:() => {},
    getPlottype:"",
    handlePlotType:() => {},
    plotData: "",
    LoadJointProject:() => {},
    NewJointProject:() => {},
    SaveJointProject:() => {},
    BacktoMain: () => {},
    BacktoMainDent: () => {},
    pageNumber:"",
    handlePageNumber: () => {},
    dentInputError:"",
    setDentInputError: () => {},
    jointInputError:"",
    setJointInputError: () => {},
    Description:"",
    alertOpen: false,
    setAlertOpen: () => {},
    jointAlertOpen: false,
    setJointAlertOpen: () => {},
    guides: true,
    setGuides: () => {},
    fullGuideLine: () => {},
})

